// src/components/GlobalNav.js
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const AppNav = ({ isMenuActive }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (accordion) => {
    setActiveAccordion(activeAccordion === accordion ? null : accordion);
  };

  const scrollToTop = () => {
    const container = document.querySelector(".content");

    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "instant",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  const scrollCollapse = () => {
    scrollToTop();
  };

  return (
    <nav className={`landing-page nav ${isMenuActive ? 'active' : ''}`} role="navigation" aria-label="main navigation">
      <ul>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "overview" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("overview")}
          >
            <NavLink 
              to="/web-home" 
              className="title-level-3 web"
              onClick={scrollCollapse}
            >
              Web
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "overview" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("overview")}
          >
            <NavLink 
              to="/app-home" 
              className="title-level-3 app"
              onClick={scrollCollapse}
            >
              App
            </NavLink>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default AppNav;
