// src/components/SubNav.js
import React from 'react';
import { Link } from 'react-router-dom';

const SubNav = ({ sections, activeSection, onSectionClick }) => {
  const handleLinkClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      onSectionClick(id);
    }
  };

  return (
    <aside className="subNav">
      <ul>
        <li>On this page:</li>
        {sections.map((section) => (
          <li
            key={section.id}
            className={section.id === activeSection ? 'active' : ''}
            onClick={() => handleLinkClick(section.id)}
          >
            <Link to={`#${section.id}`}>{section.label}</Link>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default SubNav;
