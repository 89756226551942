// src/pages/AppGrids.js
import React, { } from "react";

import TitleBar from "../../components/TitleBar";

const Grids = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Grids",
    figmaLink: "https://www.figma.com/file/c8ptjKSfQBqSFoUi1uCSJC/1---Grids?type=design&node-id=30%3A28&mode=design&t=tMfycg63qDugvqGP-1",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Coming soon.</p>
    </>
  );
};

export default Grids;
