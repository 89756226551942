// src/pages/foundation/WebSpacers.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Spacers = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Spacers",
    figmaLink: "https://www.figma.com/file/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?type=design&node-id=984-4196&mode=design"
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Most components include default spacing below them. For more complex layouts, we use a spacer component.</p>

      <div className="spacer level-2"></div>

      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th>Size</th>
              <th>Token</th>
              <th>Desktop</th>
              <th>Tablet</th>
              <th>Mobile</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rel="Size">Small</td>
                <td rel="Token">--am-spacer-sm</td>
                <td rel="Desktop">16px</td>
                <td rel="Tablet">16px</td>
                <td rel="Mobile">16px</td>
            </tr>
            <tr>
                <td rel="Size">Medium (Default)</td>
                <td rel="Token">--am-spacer-md</td>
                <td rel="Desktop">24px</td>
                <td rel="Tablet">24px</td>
                <td rel="Mobile">24px</td>
            </tr>
            <tr>
                <td rel="Size">Large</td>
                <td rel="Token">--am-spacer-lg</td>
                <td rel="Desktop">48px</td>
                <td rel="Tablet">24px</td>
                <td rel="Mobile">24px</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="spacer level-4"></div>

      <h2 className="title-level-2-small padding-level-1">Example</h2>
      <img src={process.env.PUBLIC_URL + '/images/spacers/spacer-example.png'} alt="" className="border" />

    </>
  );
};

export default Spacers;
