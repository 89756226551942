// src/pages/WebDesignHandoff.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const Handoff = () => {

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'cover-page', label: 'Cover page' },
    { id: 'usage', label: 'Usage guidelines' },
    { id: 'annotations', label: 'Annotations' },
    { id: 'markers', label: 'Markers' },
  ];

  const titleBarData = {
    eyebrowText: "Overview",
    pageTitle: "Design handoff",
    figmaLink: "https://www.figma.com/file/K4nxHinPJBo178rtQWbYoe/Handoff-Components?type=design&node-id=0-1&mode=design",
  };

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <p className="large">The following tools can be used by designers to handoff their projects to stakeholders and developers.</p>
    
      <div className="columns reverse">
        <main className="main">

          <h2 id="cover-page" className="title-level-2 padding-level-1 anchor">Cover page</h2>
          <p>The cover page for each collection of designs should include the following components to share infomation about the files and process.</p>

          <div className="columns">
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/handoff/cover-1.png'} alt="" className="border" />
              <div className="spacer level-2"></div>
              <h3 className="title-level-3-small padding-level-1">Design system</h3>
              <p>Use this cover for design system libraries, like "1 - UI Elements".</p>
            </div>
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/handoff/cover-2.png'} alt="" className="border" />
              <div className="spacer level-2"></div>
              <h3 className="title-level-3-small padding-level-1">Working files</h3>
              <p>Use this cover for collections of designs, like "Homepage &amp; Dashboard".</p>
            </div>
          </div>
          <div className="spacer level-2"></div>
          <hr />
          <div className="spacer level-2"></div>
          
          <h2 id="usage" className="title-level-2 padding-level-1 anchor">Usage guidelines</h2>
          <p>Usage guidelines are placed beside the cover page and container important information.</p>
          <div className="columns">
            <div className="col two_third">
              <img src={process.env.PUBLIC_URL + '/images/handoff/change-log.png'} alt="" />
              <div className="spacer level-2"></div>
              <h3 className="title-level-3-small padding-level-1">Change Log</h3>
              <p>The change log component contains important information about the status of our designs.</p>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/handoff/moving-components.png'} alt="" />
              <div className="spacer level-2"></div>
              <h3 className="title-level-3-small padding-level-1">Moving component</h3>
              <p>The "moving components" component is a reminder on the process of moving components in our design system. <strong>Note: Before moving or publishing components, please confirm with senior team members</strong>.</p>
            </div>
          </div>
          <div className="spacer level-2"></div>
          <img src={process.env.PUBLIC_URL + '/images/handoff/versioning.png'} alt="" />
          <div className="spacer level-2"></div>
          <h3 className="title-level-3-small padding-level-1">Versioning</h3>
          <p>The versioning component explains the structure of our design files. <strong>Note: While a max of 4 versions is ideal for our process - additional versioned layers may be required</strong>.</p>
          
          <div className="spacer level-2"></div>
          <hr />
          <div className="spacer level-2"></div>

          <h2 id="annotations" className="title-level-2 padding-level-1 anchor">Annotations</h2>
          <p>We use a annotation components to help explain general details and animation behaviours in our designs.</p>

          <div className="columns centerV">
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/handoff/annotation.png'} alt="" />
              <div className="spacer level-2"></div>
            </div>
            <div className="col one_half">
              <h3 className="title-level-3-small padding-level-1">General annotations</h3>
              <p>Probably the most used component for handoff, the "annotation" componen is used to explain overall details about a component or layout.</p>
            </div>
          </div>

          <div className="columns centerV">
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/handoff/animation.png'} alt="" />
              <div className="spacer level-2"></div>
            </div>
            <div className="col one_half">
              <h3 className="title-level-3-small padding-level-1">Animation annotations</h3>
              <p>For any element that requires details on transitions or animations use this component. Include "State" (ie. hover, onload), "Speed" (ie. 300ms) and specific details about the animation. Default speed for hover effects is <strong>300ms</strong> and <strong>500ms</strong> for "on load" transitions.</p>
            </div>
          </div>

          <div className="spacer level-2"></div>
          <hr />
          <div className="spacer level-2"></div>

          <h2 id="markers" className="title-level-2 padding-level-1 anchor">Markers</h2>
          <p>Markers are used to identify general design, accessibility, development and enhancement notes within design files. These can also be used for QA to highlight specific feedback. We also have a <strong>Marker usage component</strong> that can be added to a file to explain the different colour options.</p>

          <div className="columns">
            <div className="col one_fourth">
              <img src={process.env.PUBLIC_URL + '/images/handoff/marker-general.svg'} alt="" />
              <div className="spacer level-1"></div>
              <h4 className="title-level-4 padding-level-1">General</h4>
              <p>Used for general comments or for QA</p>
            </div>
            <div className="col one_fourth">
              <img src={process.env.PUBLIC_URL + '/images/handoff/marker-accessibility.svg'} alt="" />
              <div className="spacer level-1"></div>
              <h4 className="title-level-4 padding-level-1">Accessibility</h4>
              <p>Used to identify Accessibility requirements (ie. Alt text, screen reader copy, etc.)</p>
            </div>
            <div className="col one_fourth">
              <img src={process.env.PUBLIC_URL + '/images/handoff/marker-development.svg'} alt="" />
              <div className="spacer level-1"></div>
              <h4 className="title-level-4 padding-level-1">Accessibility</h4>
              <p>Used to explain complex development requirements. (ie. interactive components behaviours)</p>
            </div>
            <div className="col one_fourth">
              <img src={process.env.PUBLIC_URL + '/images/handoff/marker-enhancements.svg'} alt="" />
              <div className="spacer level-1"></div>
              <h4 className="title-level-4 padding-level-1">Enhancements</h4>
              <p>Used to identify enhancements made to completed designs.</p>
            </div>
          </div>
        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      
      </div>
    </>
  );
};

export default Handoff;