// src/tokens/AppTokensTypography.js
import React from "react";
import { typographyStyleTokenData, typographyRoleTokenData  } from '../../data/tokenData';

const TokensTypography = () => {
  return (
    <>
    <h2 className="title-level-2-small padding-level-2">Font Styles</h2>

    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Token</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {typographyStyleTokenData.map((item, index) => (
            <tr key={index}>
                <td rel="Token">{item.token}</td>
                <td rel="Value">{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <div className="spacer level-4"></div>

    <h2 className="title-level-2-small padding-level-2">Role</h2>

    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Token</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {typographyRoleTokenData.map((item, index) => (
            <tr key={index}>
                <td>{item.token}</td>
                <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default TokensTypography;