// src/data/ChecklistData.js
const PageChecklistData = [
  {
    task: "URL friendliness & IA",
    type: "Technical",
    groups: "UXOps & Publishing",
    details: "The URL slug needs to be friendly, short, and concise. Consult UXOps on a proper URL and where pages should live in the site structure"
  },
  {
    task: "Proper headers (H1, H2, H3)",
    type: "Content",
    groups: "Publishing",
    details: "There should only be one H1 header, H2 headers represent page sectionsm and H3 headers break up content within the page sections"
  },
  {
    task: "Compressed images",
    type: "Images",
    groups: "Brand",
    details: "All images need to be compressed. Aim to have all images below 300kb. Lower, the better"
  },
  {
    task: "Page title",
    type: "Content",
    groups: "UXOps & Publishing",
    details: "Page titles need to be unique and concise. UXOps can consult on an appropriate page title"
  },
  {
    task: "Added to sitemap",
    type: "Technical",
    groups: "UXOps & Publishing",
    details: "Ensure when a new page is published, it is added to any HTML or XML sitemap. Consult UXOps on where the page should live within the sitemap"
  },
  {
    task: "Navigation",
    type: "Content",
    groups: "UXOps & Publishing",
    details: "Review the different ways Collectors should be able to navigate to this page. Ex: Global navigation, search, breadcrumbs"
  },
  {
    task: "Meta title",
    type: "Content",
    groups: "UXOps & Publishing",
    details: "Reach out to UXOps to provide the meta title"
  },
  {
    task: "Meta description",
    type: "Content",
    groups: "UXOps & Publishing",
    details: "Reach out to UXOPs to provide the meta description"
  },
  {
    task: "Canical Tag",
    type: "Technical",
    groups: "UXOps",
    details: "Make sure every page has a self-referencing canonical tag"
  },
  {
    task: "Page expiry",
    type: "Technical",
    groups: "Publishing",
    details: "Pages that have an end date (ex. promotions) need to be unpublished or have a “closed” state"
  },
  {
    task: "Redirects",
    type: "Technical",
    groups: "UXOps",
    details: "Do any old/existing pages need to be redirected? Does this page need to be redirected when it expires? Reach out to UXOps for assistance"
  },
  {
    task: "Hide from On-site search",
    type: "Technical",
    groups: "Publishing",
    details: "Pages that should be hidden from on-site search must use “hide in sitemap” flag in AEM. (ex. mid/end flow pages like “sign up successful”)"
  },
  {
    task: "Hide from search-engines (Google)",
    type: "Technical",
    groups: "Dev/Publishing",
    details: "Pages that should be hidden from search-engines require noindex, nofollow tags"
  },
  {
    task: "SiteImprove check",
    type: "QA",
    groups: "Dev",
    details: "The SiteImprove browser extension should be used on new pages and errors corrected. Ensure the new page is crawled in the SiteImprove console"
  },
  {
    task: "Review on-site search",
    type: "QA",
    groups: "UXOps",
    details: "After launch, ensure the page is appearing in on-site search. If configuration is require, reach out to UXOps"
  },
  {
    task: "Does it pass PageSpeed score?",
    type: "Technical",
    groups: "UXOps",
    details: "Every page should be above 70% in PageSpeed score for Desktop and Mobile."
  },
];

const DesignChecklistData = [
  {
    task: "Setup or locate design file",
    details: "Website files (airmiles.ca) are all located in the Airmiles.ca folder. App files can be found in Project Flutter for most, or Project Snap (Blackbird) for Airmiles receipts. Web design system files are found in the Design System folder."
  },
  {
    task: "Layout versioning",
    details: "File versioning is to be used with layout designs. In most cases three versions is all that is required to create finalized designs. A fourth version can be used to present future enhancements. Setup a page for each version: V.O Research / Ideation, V.1 Design Exploration, V.2 Final Design, V.2.1 Enhancements. Any unused layouts can be stored in the Archives page for future use."
  },
  {
    task: "Web libraries",
    details: "All web desings (airmiles.ca) should have the following libraries included: 1 - Colours, Effects & Typography, 1 - Grids, 1 - UI Elements and 1 - Handoff Components."
  },
  {
    task: "App libraries",
    details: "All app desings (airmiles.ca) should have the following libraries included: 1 - Colours, Effects & Typography, 1 - AM App Pattern Library."
  },
  {
    task: "Creative libraries",
    details: "Creative has included a library for reusable illustrations, the following library includes: 1 - Illustrations"
  },
  {
    task: "Markup",
    details: "Use markup tools found in 1 - Handoff Components to add important notes to developers and stakeholders including, content requirements, functionality, transitions, etc."
  },
];

const PublishingChecklistData = [
  {
    task: "Acquire design from UX",
    details: "In most cases, new pages being published will have a Figma design created by UX. The components and layouts provided will match available components and layout stylings in the CMS."
  },
  {
    task: "Request reqirements from Marketing",
    details: "If content on the page needs to be targeted, Marketing will provide details on segments and condtional content areas. This includes: conditional copy, hiding and showing sections or containers to specific segments. If sections are to be laid out differently for specific segments, UX will be engaged to provide layouts for each scenario (to uphold design integrity). Marketing will also provide unique ID names for each section, container or component to avoid conflicts with other IDs on the page (either provided by Marketing or Analytics)"
  },
  {
    task: "Engage with UX for conditional layouts (optional)",
    details: "If marketing requires segmented content, UX may be required to provide additional layouts to be displayed for each segment. This is to ensure design rules are followed and design integrity is upheld."
  },
  {
    task: "Request reqirements from Analytics",
    details: "If actions or content is to be tracked by Analytics, publishing will need to include unique IDs on containers and components. Analytics will provide IDs to be included to avoid any conflicts with other IDs on the page (either provided by Marketing or Analytics)."
  },
  {
    task: "Request page review from UX",
    details: "Before a page is published, UX will review layouts to provide a top level review to ensure layouts are in compliance with the Design System. This may not be required for smaller pages, or existing page updates."
  },
];

export { PageChecklistData, DesignChecklistData, PublishingChecklistData };