// src/data/aemComponentsData.js
const aemComponentsData = [
    {
      Component: "Default Header",
      Type: "Template",
      Usage: "A layout with the default Air Miles header and default footer.",
      figmaLink: "https://www.figma.com/design/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?node-id=314-2161",
    },
    {
      Component: "Simple Header",
      Type: "Template",
      Usage: "A layout with the simple Air Miles header and default footer.",
      figmaLink: "https://www.figma.com/design/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?node-id=314-2161",
    },
    {
      Component: "Section",
      Type: "Foundational",
      Usage: "Full width sections with customizable top and bottom padding and background fills.",
      figmaLink: "https://www.figma.com/design/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?node-id=536-4206&t=ZSHq2KayyqtLt5bU-4",
    },
    {
      Component: "Container",
      Type: "Foundational",
      Usage: "Used to group content and aides in establising columns. Also used for cards, callouts, and in-page alerts.",
      figmaLink: "https://www.figma.com/design/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?node-id=684-4199&t=ZSHq2KayyqtLt5bU-4",
    },
    {
      Component: "Layout",
      Type: "Foundational",
      Usage: "While layout isn't exactly a component, it is a tool used to create any combination of columns in our 12 column grid.",
      figmaLink: "https://www.figma.com/design/c8ptjKSfQBqSFoUi1uCSJC/1---Grids?node-id=0-1",
    },
    {
      Component: "Title",
      Type: "Foundational",
      Usage: "Heading markup (<h1> - <h6>) with built in bottom margin, colour overides, superscript and subscript.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=1552-10163&t=UglXIFiOxdNjaQ3r-4",
    },
    {
      Component: "Text",
      Type: "Foundational",
      Usage: "Body copy with a WYSIWYG editor. Overriding styles include 'Eyebrow' and 'Eyebrow Slim' styles, colour overrides, xSmall, Small and Large sizing, Blockquotes, inline links and some text with icons.",
      figmaLink: "https://www.figma.com/design/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?node-id=0-1",
    },
    {
      Component: "Breadcrumb",
      Type: "Pattern",
      Usage: "Part of the default header template. Automatically added, and dynamically populated.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=5255-19686",
    },
    {
      Component: "Image",
      Type: "Pattern",
      Usage: "An image component with styling options to add bottom margins, and border radius (independent).",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=3992-24999",
    },
    {
      Component: "Accordion",
      Type: "Pattern",
      Usage: "Add a single or group of accordions. Label has the option to include an icon (from a specified list). Accordions can be open or closed by default.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=8198-31693",
    },
    {
      Component: "Buttons",
      Type: "Pattern",
      Usage: "A single component that has the ability to display a primary, secondary or tertiary button. Allows for different sizes, and ability to include an icon (in different locations).",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=3992-18607",
    },
    {
      Component: "Tabs",
      Type: "Pattern",
      Usage: "A component that allows for groups of content to be toggled via tabs. Content area can accept any component or group of components.",
      figmaLink: "#https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=5285-13281",
    },
    {
      Component: "Core Form Container",
      Type: "Form",
      Usage: "A container used to group form elements. Allows for form validations, form actions and redirects.",
      figmaLink: "",
    },
    {
      Component: "Form Buttons",
      Type: "Form",
      Usage: "Resembling our 'Primary' button style, the form button uses the <button> HTML markup and is used for form submissions.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=5530-14618",
    },
    {
      Component: "Form Hidden",
      Type: "Form",
      Usage: "A hidden form field used to pass values on form submission.",
      figmaLink: "",
    },
    {
      Component: "Form Options",
      Type: "Form",
      Usage: "Allows for the implementation of Checkboxes, Radio buttons (in development), Drop-downs and Multi-select (in development).",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=764-2458&t=UglXIFiOxdNjaQ3r-4",
    },
    {
      Component: "Form Text",
      Type: "Form",
      Usage: "Allows for the implementation of text fields, textareas, email fields, telephone fields, date fields (with date picker), time fields, number fields and password fields. All fields allow for Regex validation. In addition, custom functionality is enabled for address lookup with mapping.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=764-2499&t=UglXIFiOxdNjaQ3r-4",
    },
    {
      Component: "Requirement List Validation",
      Type: "Form",
      Usage: "A list of input field requirements that dynamically changes as user fills out an input field.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=7711-23531",
    },
    {
      Component: "Spinner",
      Type: "Form",
      Usage: "An animated graphic used to represent a 'loading' state. *** To be updated with rebranded elements ***",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=926-3584&t=UglXIFiOxdNjaQ3r-4",
    },
    {
      Component: "Teaser",
      Type: "Pattern",
      Usage: "A more rigid card component that is actionable by click (or tapping) anywhere within the card. Will include conditional subtitle, title, detaisl, image and CTA button. Future enhancements will include the ability to add additional content types (ie. icons, pills, etc.)",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=11679-18515",
    },
    {
      Component: "Progress Stepper",
      Type: "Pattern",
      Usage: "A configurable progress stepper component that sits above content to indicate the position a user is in, in a specific flow.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=5087-20238",
    },
    {
      Component: "Modal",
      Type: "Pattern",
      Usage: "A modal container that sits ontop of a full screen overlay (semi-transparent) that can incorporate any component(s) and layout.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=7711-23527",
    },
    {
      Component: "Embed",
      Type: "Pattern",
      Usage: "Used to embed AMP media ads, youTube Embeds, and custom HTML into pages",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=11333-1822",
    },
    {
      Component: "Quick Link",
      Type: "Pattern",
      Usage: "Used primarily in DSSE and Dashboard. A visual tool to identify links with an icon, title and optional description.",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=11684-19137",
    },
    {
      Component: "Carousel",
      Type: "Pattern",
      Usage: "A versatile component that is composed of carousel controls (previous and next arrows), external controls (slide position indicator) and an area for slides to be added with mixed content. In carousels that display one slide at a time, a maximum of 3 slides can be included (ie. home carousel & Dashboard carousel).",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=11955-16361",
    },
    {
      Component: "Toggle",
      Type: "Pattern",
      Usage: "A modified radio button that is used to display on / off settings",
      figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=11957-16371",
    },
];

export default aemComponentsData;
