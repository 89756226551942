// src/components/ChangeLog.js
import React from "react";
import PropTypes from "prop-types";

const ChangeLog = ({ limitToOne, data }) => {
  const visibleData = limitToOne ? [data[0]] : data;

  return (
    <div className="change-log-container">
      <div className="change-log-container-header">
        <div className="col">Date</div>
        <div className="col">Version</div>
        <div className="col">Notes</div>
      </div>

      {visibleData.map((log, index) => (
        <div key={index} className="change-log-container-row">
          <div className="col">{log.date}</div>
          <div className="col">{log.version}</div>
          <div className="col">
            <div dangerouslySetInnerHTML={{ __html: log.notes }} />
          </div>
        </div>
      ))}
    </div>
  );
};

ChangeLog.propTypes = {
  limitToOne: PropTypes.bool,
  data: PropTypes.array.isRequired,
};

export default ChangeLog;