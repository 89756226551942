// src/pages/WebTypography.js
import React, { useState } from "react";
import TypographyDesktop from "../typography/TypographyDesktop";
import TypographyTablet from "../typography/TypographyTablet";
import TypographyMobile from "../typography/TypographyMobile";
import TypographyBody from "../typography/TypographyBody";
import TypographyBodyDefinitions from "../typography/TypographyBodyDefinitions";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const Typography = () => {
  const [activeTab, setActiveTab] = useState("Desktop");
  const [activeSection, setActiveSection] = useState("overview");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'headings', label: 'Web Headings' },
    { id: 'bodyCopy', label: 'Web Body' },
    { id: 'typogtaphyDefinitions', label: 'Typography Definitions' },
  ];

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Typography",
    figmaLink: "https://www.figma.com/file/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?type=design&node-id=2235-1901&mode=design"
  };

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <div className="columns reverse relative">
        <main className="main">
          <p className="large">We use two typefaces in our design system: <span className="highlight">"Work Sans"</span> for headings, eyebrows and buttons. <span className="highlight alt">"Open Sans"</span> for body copy (all sizes).</p>

          <h2 id="headings" className="title-level-2 padding-level-2 anchor">Headings</h2>

          <h3 className="title-level-3 padding-level-1">Usage</h3>

          <p><strong>Spacing:</strong> Default spacing (bottom padding) for h1 & h2 is 24px. h1 & h2 can have “small” spacing which is 16px bottom padding, or “large” spacing” which is 32px bottom padding. Default spacing for h3 is 8px bottom padding.</p>
          <p><strong>Sizing*:</strong> Heading styles 1-3 have overriding sizes for XSmall*, Small, Large, XLarge*</p>
          <p><strong>Colour:</strong> All Headings are midnight-700 on light backgrounds and White on dark backgrounds. An overide is available for H1, H1 Large, H1 Small, H2, H2 Large, H2 XLarge on light backgrounds to include a "hot-pink-500" Option, and "hot-pink-500", "lavender-400", "electric-lime-500" on "midnight-700" backgrounds.</p>
          <p><strong>Structure:</strong> Some content blocks will use heading styles for titles (ie. offer card). While the style should match what is defined (ie. h3) depending on what the component requires for SEO / Accessibility - the text may not use an "h" tag, but a span with css that matches the styling of an "h" tag.</p>
          
          <div className="callout">
            <h3 className="title-level-3-large padding-level-1">*Sizing Rules</h3>

            <p>Sizes cascade through heading levels. For example, if H1 "Large" is used, then H2, H3, etc. would use their "Large" option as well. In some cases, <strong>this rule can be ignored if a cascaded heading is too large or small based on other page components and layout.</strong></p>
          </div>

          <div className="spacer level-2"></div>

          <div className="table">
            <table cellPadding="0" cellSpacing="0" border="none">
              <thead className="sticky">
                <tr>
                  <th>Size</th>
                  <th>Rules</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td rel="Size">Small</td>
                    <td rel="Rules">Used primarily for text heavy pages (like legal pages), with minimal graphical elements.</td>
                </tr>
                <tr>
                    <td rel="Size">Default</td>
                    <td rel="Rules">Most commonly used. Used on informational pages.</td>
                </tr>
                <tr>
                    <td rel="Size">Large</td>
                    <td rel="Rules">Used primarily for marketing pages where content is aspirational and messaging is more graphical.</td>
                </tr>
              </tbody>
            </table>
          </div>


          <div className="spacer level-2"></div>

          <h2 id="bodyCopy" className="title-level-2 padding-level-2 anchor">Body copy</h2>

          <TypographyBody />

          <div className="spacer level-1"></div>

          <h2 id="typogtaphyDefinitions" className="title-level-2 padding-level-2 anchor">Typography Definitions</h2>

          <div className="spacer level-1"></div>
          
          <div className="tabsContainer">
            <nav className="tabs">
              <ul>
                <li
                  className={activeTab === "Desktop" ? "active" : ""}
                  onClick={() => handleTabClick("Desktop")}
                >
                  Desktop
                </li>
                <li
                  className={activeTab === "Tablet" ? "active" : ""}
                  onClick={() => handleTabClick("Tablet")}
                >
                  Tablet
                </li>
                <li
                  className={activeTab === "Mobile" ? "active" : ""}
                  onClick={() => handleTabClick("Mobile")}
                >
                  Mobile
                </li>
                <li
                  className={activeTab === "WebBody" ? "active" : ""}
                  onClick={() => handleTabClick("WebBody")}
                >
                  Web Body
                </li>
              </ul>
            </nav>
          </div>

          {activeTab === "Desktop" && <TypographyDesktop />}
          {activeTab === "Tablet" && <TypographyTablet />}
          {activeTab === "Mobile" && <TypographyMobile />}
          {activeTab === "WebBody" && <TypographyBodyDefinitions />}
          
          <div className="spacer level-2"></div>

        </main>
      
        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Typography;