// src/pages/WebNewDesignChecklist.js
import React from "react";

import TitleBar from "../../components/TitleBar";

import { DesignChecklistData } from '../../data/checklistData';

const CheckList = () => {

  const titleBarData = {
    eyebrowText: "Resources",
    pageTitle: "New design checklist",
  };

  let counter = 0;

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">The following checks should be made for <strong><em>every new figma design</em></strong>. These are in place to ensure proper <strong>libraries</strong> are loaded to access all components and styles.</p>
      
      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th></th>
              <th>Task</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {DesignChecklistData.map((item, index) => (
              <tr key={index}>
                <td rel="step">{++counter}</td>
                <td rel="task">{item.task}</td>
                <td rel="details">{item.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CheckList;