// src/pages/WebImages.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Images = () => {

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Image containers",
    figmaLink: "https://www.figma.com/file/v6oz764LRzabocmWqiFagF/1---UI-Elements?type=design&node-id=764-2984&mode=design&t=JgBqWlaAGs6Q8cIB-4",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">When applying images to components and layout, we use a set number of aspect ratios. Image container scales proportionately to the selected aspect ratio. Available aspect ratios include:</p>

      <ol className="inline">
        <li>1:1</li>
        <li>2:1</li>
        <li>16:9</li>
        <li>3:2</li>
        <li>4:3</li>
        <li>4:1</li>
        <li>1:2</li>
        <li>9:16</li>
        <li>2:3</li>
        <li>3:4</li>
        <li>1:4</li>
        <li>3:1</li>
        <li>1:3</li>
      </ol>

      <img src={process.env.PUBLIC_URL + '/images/image-containers/image-container.png'} alt="" />

      <div className="spacer level-4"></div>

      <h3 className="title-level-3-large padding-level-2">Image Settings</h3>

          <div className="table">
            <table cellPadding="0" cellSpacing="0" border="none">
              <thead className="sticky">
                <tr>
                  <th>Option</th>
                  <th>Desktop</th>
                  <th>Tablet landscape</th>
                  <th>Tablet portrait</th>
                  <th>Mobile</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan="5">Bottom Margin</th>
                </tr>
                <tr>
                  <td rel="Option">None</td>
                  <td rel="Desktop">0px</td>
                  <td rel="Tablet landscape">0px</td>
                  <td rel="Tablet portrait">0px</td>
                  <td rel="Mobile">0px</td>
                </tr>
                <tr>
                  <td rel="Option">XSmall</td>
                  <td rel="Desktop">8px</td>
                  <td rel="Tablet landscape">8px</td>
                  <td rel="Tablet portrait">8px</td>
                  <td rel="Mobile">8px</td>
                </tr>
                <tr>
                  <td rel="Option">Small</td>
                  <td rel="Desktop">16px</td>
                  <td rel="Tablet landscape">16px</td>
                  <td rel="Tablet portrait">16px</td>
                  <td rel="Mobile">16px</td>
                </tr>
                <tr>
                  <td rel="Option">Medium</td>
                  <td rel="Desktop">24px</td>
                  <td rel="Tablet landscape">24px</td>
                  <td rel="Tablet portrait">24px</td>
                  <td rel="Mobile">24px</td>
                </tr>
                <tr>
                  <td rel="Option">Large</td>
                  <td rel="Desktop">48px</td>
                  <td rel="Tablet landscape">48px</td>
                  <td rel="Tablet portrait">48px</td>
                  <td rel="Mobile">48px</td>
                </tr>
                <tr>
                  <th colSpan="5">Border Radius<br /><small>Border radius can have indpenent options selected for each corner</small></th>
                </tr>
                <tr>
                  <td rel="Option">None</td>
                  <td rel="Desktop">0px</td>
                  <td rel="Tablet landscape">0px</td>
                  <td rel="Tablet portrait">0px</td>
                  <td rel="Mobile">0px</td>
                </tr>
                <tr>
                  <td rel="Option">Medium</td>
                  <td rel="Desktop">8px</td>
                  <td rel="Tablet landscape">8px</td>
                  <td rel="Tablet portrait">8px</td>
                  <td rel="Mobile">8px</td>
                </tr>
                <tr>
                  <td rel="Option">Large</td>
                  <td rel="Desktop">16px</td>
                  <td rel="Tablet landscape">16px</td>
                  <td rel="Tablet portrait">16px</td>
                  <td rel="Mobile">16px</td>
                </tr>
              </tbody>
            </table>
          </div>

      <div className="spacer level-4"></div>

      <h2 className="title-level-2 padding-level-2">Using Images in AEM</h2>

      <div className="columns">
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-3">Adding images to layout</h3>
          <ul className="bullets">
            <li>When adding images to a layout, they are sized based on the group of columns they are in</li>
            <li>We use a 12 column grid, that can be nested inside of another container.</li>
            <li>The width and gutters between images (horizontally) are based on the 12 column grid</li>
            <li>Space below images can be controlled by the bounding container, or by adding a bottom margin to the image component.</li>
          </ul>
        </div>
        <div className="col two_third">
          <img src={process.env.PUBLIC_URL + '/images/image-containers/image-in-grid.png'} alt="" />
        </div>
      </div>

      <div className="spacer level-4"></div>
    
      <h2 className="title-level-2 padding-level-2">Using Images in Figma &amp; Exporting for web</h2>

      <div className="columns">
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">Applying an image to container</h3>
          <ul className="bullets">
            <li>Use the “image” variant to easily copy and paste PNGs or JPGs.</li>
            <li>This sets the image as an Image Fill</li>
            <li>In most cases the “fill” option is the correct option. (see screenshot)</li>
            <li>Image “fill” emulates the “background-size: cover” css technique.</li>
          </ul>
          <p className="small"><strong>Note:</strong> When handing off designs to development, it should be mentioned how an image is meant to be placed in a container. Is the image embeded on the page (scale proportionately) or is it a background image that gets cropped as the container scales?</p>
        </div>
        <div className="col two_third">
          <img src={process.env.PUBLIC_URL + '/images/image-containers/Image-application.png'} alt="" />
        </div>
      </div>

      <div className="spacer level-4"></div>

      <div className="columns">
        <div className="col one_third">
          <h2 className="title-level-2-small padding-level-2">Images for handoff</h2>
          <ul className="bullets">
            <li>Images should be saved at 2X the render size (for retina displays)</li>
            <li>Before images are used in web, the should be saved at 72dpi and compressed</li>
            <li>If an image <strong>is not</strong> decorative, ALT text should be provided</li>
            <li>Acceptable file formats include:
              <ul>
                <li><strong>PNG:</strong> For small and simple images like logos, icons, etc. (and images with transparencies)</li>
                <li><strong>JPG:</strong> For complex images like photographs</li>
                <li><strong>GIF:</strong> For animated graphics (deprecated)</li>
                <li><strong>SVG:</strong> To replace simple PNG graphics, and animated graphics</li>
                <li><strong>Webp:</strong> Not supported at this time - for future projects</li>
              </ul>
            </li>
          </ul>
          <p className="small"><strong>Pro Tip:</strong> An easy way to compress images is to use free tools like <a href="https://tinypng.com" rel="noreferrer" target="_blank">TinyPNG</a>. (this tool can be used for PNG, JPG and WebP formats only. Does not apply to SVG or GIF).</p>
        </div>
        <div className="col two_third">
          <img src={process.env.PUBLIC_URL + '/images/image-containers/image-handoff.png'} alt="" />
        </div>
      </div>
    </>
  );
};

export default Images;
