// src/tokens/WebTokensColours.js
import React from "react";
import { coloursBrandData, coloursFunctionalData, coloursTierData, coloursGradientsData} from '../../data/coloursData';

const TokensColours = () => {
  return (
    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Token</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          
          {coloursBrandData.map((group) => (
            <React.Fragment key={group.name}>
              {group.colors.map((color, index) => (
                <tr key={`${group.name}-${index}`}>
                  <td rel="Token">{color.coreTokenName}</td>
                  <td rel="Value">{color.hex}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}

          {coloursFunctionalData.map((group) => (
            <React.Fragment key={group.name}>
              {group.colors.map((color, index) => (
                <tr key={`${group.name}-${index}`}>
                  <td rel="Token">{color.coreTokenName}</td>
                  <td rel="Value">{color.hex}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}

          {coloursTierData.map((group) => (
            <React.Fragment key={group.name}>
              {group.colors.map((color, index) => (
                <tr key={`${group.name}-${index}`}>
                  <td rel="Token">{color.coreTokenName}</td>
                  <td rel="Value">{color.hex}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}

          {coloursGradientsData.map((group) => (
            <React.Fragment key={group.name}>
              {group.colors.map((color, index) => (
                <tr key={`${group.name}-${index}`}>
                  <td rel="Token">{color.coreTokenName}</td>
                  <td rel="Value">linear-gradient(to bottom, {color.hex1} 0%,{color.hex2} 100%);</td>
                </tr>
              ))}
            </React.Fragment>
          ))}

        </tbody>
      </table>
    </div>
  );
};

export default TokensColours;