// src/pages/accessibility/WebAccessibilityOverview.js
import React from "react";

import AccessibilityGlobal from "../../pages/accessibility/AccessibilityOverview";

const Accessibility = () => {

  return (
    <>
      <AccessibilityGlobal />
    </>
  );
};

export default Accessibility;