// src/pages/WebLayouts.js
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const Layouts = () => {

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'composition', label: 'Composition' },
    { id: 'outer', label: 'Section' },
    { id: 'inner', label: 'Container' },
    { id: 'breakpoints', label: 'Breakpoints' },
  ];

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Layout",
    figmaLink: "https://www.figma.com/file/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?type=design&node-id=684%3A4199&mode=design&t=P5HO8TYxCouYMPE8-1",
  };

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <p className="large">Our layouts are composed of a header and footer, <span className="highlight">reusable / stackable</span> Sections, reusable Containers, and a 12 column grid. Within this grid a library of reusable components are added inside of various column combinations and containers.</p>

      <div className="columns reverse">
        <main className="main">

          <h2 id="composition" className="title-level-2 padding-level-3 anchor">Layout composition</h2>

          <img src={process.env.PUBLIC_URL + '/images/layout/layout-composition.png'} alt="" />

          <div className="spacer level-2"></div>
            
          <h3 className="title-level-3 padding-level-1">Header &amp; Footer</h3>
          <p>Our default template has 2 header options: <strong>Default</strong> and <strong>Simple</strong>. The Default header (<a href="https://www.figma.com/file/v6oz764LRzabocmWqiFagF/1---UI-Elements?type=design&node-id=941-3584&mode=design&t=d4gvFYXQYSRPKHXK-4" target="_blank" rel="noreferrer">see header component</a>) is used for the majority of pages. The simple header is used for transactional pages and some marketing landing pages. There is currently only one Footer used across all pages.</p>

          <h3 className="title-level-3 padding-level-1">Section</h3>
          <p>Sections are stackable, are full width and include variants for top/bottom padding and background colour. Padding values vary across different breakpoints.</p>

          <h3 className="title-level-3 padding-level-1">Container</h3>
          <p>Containers are inside all Sections. They include variants for size, padding, border radius, background fill, bottom margin and box-shadow. Padding and border radius vary across different breakpoints. <strong>Containers can be nested</strong>.</p>

          <h3 className="title-level-3 padding-level-1">Grid</h3>
          <p>See <NavLink to="/grids" activeClassName="is-active">Grids</NavLink> for usage guidelines.</p>

          <h3 className="title-level-3 padding-level-1">Components</h3>
          <p>Components (and component groups) have fluid widths that occupy the full width of the selected column combination. Any component in our UI library are able to be dropped into a layout. Most components have there own bottom margin (or options to add bottom margins)</p>

          <div className="spacer level-2"></div>

          <h2 id="outer" className="title-level-2 padding-level-2 anchor">Sections</h2>

          <p className="large">Sections are full width containers that are used to organize content on a page. Sections have options for <strong>top and bottom padding (can be independent) and background fills</strong>. Below are settings available.</p>
          
          <div className="spacer level-2"></div>

          <h3 className="title-level-3-large padding-level-2">Section settings</h3>

          <div className="table">
            <table cellPadding="0" cellSpacing="0" border="none">
              <thead className="sticky">
                <tr>
                  <th>Option</th>
                  <th>Desktop</th>
                  <th>Tablet landscape</th>
                  <th>Tablet portrait</th>
                  <th>Mobile</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan="5">Padding top</th>
                </tr>
                <tr>
                  <td rel="Option">None</td>
                  <td rel="Desktop">0px</td>
                  <td rel="Tablet landscape">0px</td>
                  <td rel="Tablet portrait">0px</td>
                  <td rel="Mobile">0px</td>
                </tr>
                <tr>
                  <td rel="Option">XSmall</td>
                  <td rel="Desktop">24px</td>
                  <td rel="Tablet landscape">24px</td>
                  <td rel="Tablet portrait">24px</td>
                  <td rel="Mobile">24px</td>
                </tr>
                <tr>
                  <td rel="Option">Small</td>
                  <td rel="Desktop">48px</td>
                  <td rel="Tablet landscape">48px</td>
                  <td rel="Tablet portrait">24px</td>
                  <td rel="Mobile">24px</td>
                </tr>
                <tr>
                  <td rel="Option">Medium</td>
                  <td rel="Desktop">64px</td>
                  <td rel="Tablet landscape">64px</td>
                  <td rel="Tablet portrait">48px</td>
                  <td rel="Mobile">48px</td>
                </tr>
                <tr>
                  <td rel="Option">Large</td>
                  <td rel="Desktop">80px</td>
                  <td rel="Tablet landscape">64px</td>
                  <td rel="Tablet portrait">64px</td>
                  <td rel="Mobile">48px</td>
                </tr>
                <tr>
                  <th colSpan="5">Padding bottom</th>
                </tr>
                <tr>
                  <td rel="Option">None</td>
                  <td rel="Desktop">0px</td>
                  <td rel="Tablet landscape">0px</td>
                  <td rel="Tablet portrait">0px</td>
                  <td rel="Mobile">0px</td>
                </tr>
                <tr>
                  <td rel="Option">XSmall</td>
                  <td rel="Desktop">24px</td>
                  <td rel="Tablet landscape">24px</td>
                  <td rel="Tablet portrait">24px</td>
                  <td rel="Mobile">24px</td>
                </tr>
                <tr>
                  <td rel="Option">Small</td>
                  <td rel="Desktop">48px</td>
                  <td rel="Tablet landscape">48px</td>
                  <td rel="Tablet portrait">24px</td>
                  <td rel="Mobile">24px</td>
                </tr>
                <tr>
                  <td rel="Option">Medium</td>
                  <td rel="Desktop">64px</td>
                  <td rel="Tablet landscape">64px</td>
                  <td rel="Tablet portrait">48px</td>
                  <td rel="Mobile">48px</td>
                </tr>
                <tr>
                  <td rel="Option">Large</td>
                  <td rel="Desktop">80px</td>
                  <td rel="Tablet landscape">64px</td>
                  <td rel="Tablet portrait">64px</td>
                  <td rel="Mobile">48px</td>
                </tr>
                <tr>
                  <th colSpan="5">
                    Background fill
                    <small>For dark and darkest blue, text changes to white automatically</small>
                  </th>
                </tr>
                <tr>
                  <td rel="Option">White</td>
                  <td rel="All" colSpan="4">--am-base-colour-white</td>
                </tr>
                <tr>  
                  <td rel="Option">Oatmylk 100</td> 
                  <td rel="All" colSpan="4">
                    <div>
                      --am-base-colour-oatmylk-100
                    </div>
                  </td>
                </tr>
                <tr>  
                  <td rel="Option">lavender 400</td> 
                  <td rel="All" colSpan="4">
                    <div>
                    --am-base-colour-lavender-400
                    </div>
                  </td>
                </tr>
                <tr>
                  <td rel="Option">Sapphire 100</td>
                  <td rel="All" colSpan="4">--am-base-colour-sapphire-100</td>
                </tr>
                <tr>
                  <td rel="Option">Sapphire 200</td>
                  <td rel="All" colSpan="4">--am-base-colour-sapphire-200</td>
                </tr>
                <tr>
                  <td rel="Option">Sapphire 900</td>
                  <td rel="All" colSpan="4">
                    <div>
                      --am-base-colour-sapphire-900
                    </div>
                  </td>
                </tr>
                <tr>
                  <td rel="Option">Midnight 700</td>
                  <td rel="All" colSpan="4">--am-base-colour-midnight-700</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="spacer level-4"></div>

          <h3 className="title-level-3-large padding-level-2">Section usecases</h3>
          
          <div className="callout white">

            <img src={process.env.PUBLIC_URL + '/images/layout/outer-container-desktop-none.png'} alt="" className="border" />
            <div className="spacer level-1"></div>
            <p><strong>Top and bottom padding: None</strong></p>
            <p>Use this padding when a component has its own top and bottom padding, or if an image needs to be full height of a conainer (touching the top and bottom of the container).</p>
          </div>

          <div className="spacer level-2"></div>
  
          <div className="callout white">
            <img src={process.env.PUBLIC_URL + '/images/layout/outer-container-desktop-xsmall.png'} alt="" className="border" />
            <div className="spacer level-1"></div>
            <p><strong>Top and bottom padding: XSmall</strong></p> 
            <p>Used with AMP ads</p>
            <p className="small"><strong>Example pages:</strong> Home, Offers</p>
          </div>

          <div className="spacer level-2"></div>
  
          <div className="callout white">
            <img src={process.env.PUBLIC_URL + '/images/layout/outer-container-desktop-small.png'} alt="" className="border" />
            <div className="spacer level-1"></div>
            <p><strong>Top and bottom padding: Small</strong></p> 
            <p>Used on pages with a single section and for padding in heroes (first section on a page). Used in pages with heavy text (ie. legal copy, terms & conditions)</p>
            <p className="small"><strong>Example pages:</strong> Terms &amp; Conditions, Privacy Policy</p>
          </div>

          <div className="spacer level-2"></div>              

          <div className="callout white">
            <img src={process.env.PUBLIC_URL + '/images/layout/outer-container-desktop-medium.png'} alt="" className="border" />
            <div className="spacer level-1"></div>
            <p><strong>Top and bottom padding: Medium</strong></p>
            <p>Used for sections below a hero (if hero is in a section with a different coloured background than the rest of the page)</p>
            <p className="small"><strong>Example:</strong> Sections below hero on unAuthenticated home page</p>
          </div>

          <div className="spacer level-2"></div>

          <div className="callout white">
            <img src={process.env.PUBLIC_URL + '/images/layout/outer-container-desktop-default.png'} alt="" className="border" />
            <div className="spacer level-1"></div>
            <p><strong>Top and bottom padding: Large</strong></p>
            <p>Used on pages that have multiple containers stacked with alternating background fills (Zebra Pattern). Or used for areas with small amount of content that requires more “breathing room”. Large bottom padding is used for most containers that <strong>end at the Footer</strong> (can be ignored if it effects the flow of the page). Do not stack large padding sized containers with the same background fill (space between sections will be too great)</p>
            <p className="small"><strong>Example:</strong> 404 page, Moments page.</p>
          </div>

          <div className="spacer level-4"></div>

          <h3 className="title-level-3-large padding-level-2">Section background fill</h3>

          <p className="large">The Default outter container fill is "White" (--am-base-colour-white). Use one of the other options to add emphasis to a section, or to create hierarchy.</p>

          <h3 className="title-level-3 padding-level-2">Examples</h3>

          <div className="columns noWrap">
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/layout/layout-fill-do.png'} alt="" className="border" />
              <div className="spacer level-2"></div>
              <span className="tag-do">Do</span>
              <p>Use stacked Default sized containers with alternating background fills.</p>
            </div>
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/layout/layout-fill-dont.png'} alt="" className="border" />
              <div className="spacer level-2"></div>
              <span className="tag-dont">Don't</span>
              <p>Use stacked Default sized containers with the same background fill.</p>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <h2 id="inner" className="title-level-2 padding-level-2 anchor">Container</h2>

          <p className="large">The <em>Container</em> sits inside of every <em>Section</em>. The container can occupy any combination of columns within a max width of 1140px. For this to work, their needs to be a container to wrap inner content. This container's "type" is set to "inner content". Containers also have the ability to allow for content to be positioned vertically.</p>
          
          <div className="spacer level-2"></div>

          <h3 className="title-level-3-large padding-level-2">Container Settings</h3>

          <p>To help publishers create dynamic layouts with various containers, we have enabled settings for the our containers to allow for; <strong>padding, border-radius, box shadows, bottom margins, vertical &amp; horizontal alignments and background fills</strong>. We also have the option for different "types". These can be used for: <strong>Inner containers (max width of 1140px and centered), alerts (info, error, warning and success), and button group (horizontally aligned buttons that aren't fixed to the grid)</strong>. Below is a table that shows all options and default states.</p>

          <div className="spacer level-1"></div>

          <div className="table">
            <table cellPadding="0" cellSpacing="0" border="none">
              <thead className="sticky">
                <tr>
                  <th>Option</th>
                  <th>Desktop</th>
                  <th>Tablet landscape</th>
                  <th>Tablet portrait</th>
                  <th>Mobile</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan="5">Padding</th>
                </tr>
                <tr>
                    <td rel="Option">None</td>
                    <td rel="Desktop">0px</td>
                    <td rel="Tablet landscape">0px</td>
                    <td rel="Tablet portrait">0px</td>
                    <td rel="Mobile">0px</td>
                </tr>
                <tr>
                    <td rel="Option">XSmall</td>
                    <td rel="Desktop">8px</td>
                    <td rel="Tablet landscape">8px</td>
                    <td rel="Tablet portrait">8px</td>
                    <td rel="Mobile">
                      <div>
                      8px
                      </div>
                    </td>
                </tr>
                <tr>
                    <td rel="Option">Small</td>
                    <td rel="Desktop">16px</td>
                    <td rel="Tablet landscape">16px</td>
                    <td rel="Tablet portrait">16px</td>
                    <td rel="Mobile">16px</td>
                </tr>
                <tr>
                    <td rel="Option">Medium</td>
                    <td rel="Desktop">24px</td>
                    <td rel="Tablet landscape">24px</td>
                    <td rel="Tablet portrait">24px</td>
                    <td rel="Mobile">16px</td>
                </tr>
                <tr>
                    <td rel="Option">Large</td>
                    <td rel="Desktop">48px</td>
                    <td rel="Tablet landscape">24px</td>
                    <td rel="Tablet portrait">24px</td>
                    <td rel="Mobile">16px</td>
                </tr>
                <tr>
                  <th colSpan="5">
                    <div>
                      Margin Bottom
                    </div>
                  </th>
                </tr>
                <tr>
                    <td rel="Option">None</td>
                    <td rel="Desktop">0px</td>
                    <td rel="Tablet landscape">0px</td>
                    <td rel="Tablet portrait">0px</td>
                    <td rel="Mobile">0px</td>
                </tr>
                <tr>
                    <td rel="Option">Small</td>
                    <td rel="Desktop">24px</td>
                    <td rel="Tablet landscape">24px</td>
                    <td rel="Tablet portrait">24px</td>
                    <td rel="Mobile">24px</td>
                </tr>
                <tr>
                    <td rel="Option">Medium</td>
                    <td rel="Desktop">48px</td>
                    <td rel="Tablet landscape">48px</td>
                    <td rel="Tablet portrait">24px</td>
                    <td rel="Mobile">24px</td>
                </tr>
                <tr>
                  <th colSpan="5">Border radius</th>
                </tr>
                <tr>
                    <td rel="Option">None</td>
                    <td rel="Desktop">0px</td>
                    <td rel="Tablet landscape">0px</td>
                    <td rel="Tablet portrait">0px</td>
                    <td rel="Mobile">0px</td>
                </tr>
                <tr>
                    <td rel="Option">Medium</td>
                    <td rel="Desktop">8px</td>
                    <td rel="Tablet landscape">8px</td>
                    <td rel="Tablet portrait">8px</td>
                    <td rel="Mobile">8px</td>
                </tr>
                <tr>
                    <td rel="Option">Large</td>
                    <td rel="Desktop">16px</td>
                    <td rel="Tablet landscape">16px</td>
                    <td rel="Tablet portrait">16px</td>
                    <td rel="Mobile">8px</td>
                </tr>
                <tr>
                  <th colSpan="5">
                    <div>
                      Vertical Alignment
                    </div>
                  </th>
                </tr>
                <tr>
                  <td rel="Option">None</td> 
                  <td rel="All" colSpan="4">-</td>
                </tr>
                <tr>
                  <td rel="Option">Center</td> 
                  <td rel="All" colSpan="4">align-items:center</td>
                </tr>
                <tr>
                  <td rel="Option">Bottom</td> 
                  <td rel="All" colSpan="4">align-items:bottom</td>
                </tr>
                <tr>
                  <th colSpan="5">Box shadow</th>
                </tr>
                <tr>
                    <td rel="Option">Off</td>
                    <td rel="All" colSpan="4">box-shadow: none;</td>
                </tr>
                <tr>
                    <td rel="Option">On</td>
                    <td rel="All" colSpan="4">box-shadow: 0px 2px 2px 0px rgba(40, 47, 64, 0.1)</td>
                </tr>
                <tr>
                  <th colSpan="5">Background fill</th>
                </tr>
                <tr>
                    <td rel="Option">None </td>
                    <td rel="All" colSpan="4">Transparent</td>
                </tr>
                <tr>
                  <td rel="Option">White</td> 
                  <td rel="All" colSpan="4">--am-base-colour-white</td>
                </tr>
                <tr>  
                  <td rel="Option">Oatmylk 100</td> 
                  <td rel="All" colSpan="4">
                    <div>
                      --am-base-colour-oatmylk-100
                    </div>
                  </td>
                </tr>
                <tr>  
                  <td rel="Option">lavender 400</td> 
                  <td rel="All" colSpan="4">
                    <div>
                    --am-base-colour-lavender-400
                    </div>
                  </td>
                </tr>
                <tr>
                  <td rel="Option">Sapphire 100</td> 
                  <td rel="All" colSpan="4">--am-base-colour-sapphire-100</td>
                </tr>
                <tr>  
                  <td rel="Option">Sapphire 200</td> 
                  <td rel="All" colSpan="4">--am-base-colour-sapphire-200</td>
                </tr>
                <tr>  
                  <td rel="Option">Sapphire 900</td> 
                  <td rel="All" colSpan="4">
                    <div>
                      --am-base-colour-sapphire-900
                    </div>
                  </td>
                </tr>
                <tr>  
                  <td rel="Option">Midnight 700</td> 
                  <td rel="All" colSpan="4">--am-base-colour-midnight-700</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="spacer level-2"></div>

          <h2 id="breakpoints" className="title-level-2 padding-level-2 anchor">Breakpoints</h2>

          <p class="large">Our layouts work with 4 responsive breakpoints. Our default Container width is 1140px, and the first breakpoint is triggered at 1236px (1140px + 48px on the left and right). The following table shows the media-query breakpoints of our design system.</p>
        
          <div className="spacer level-1"></div>

          <div className="table">
            <table cellPadding="0" cellSpacing="0" border="none">
              <thead>
                <tr>
                  <th>Screen Size</th>
                  <th>Media Query</th>
                  <th>Left / Right Padding</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td rel="Screen Size">Desktop</td>
                    <td rel="Media Query">Anything greater than 1236px</td>
                    <td rel="Left / Right Padding">Auto</td>
                </tr>
                <tr>
                  <td rel="Screen Size">Tablet Landscape</td>
                  <td rel="Media Query">@media screen and (min-width:951px) and (max-width:1236px)</td>
                  <td rel="Left / Right Padding">48px</td>
                </tr>
                <tr>
                  <td rel="Screen Size">Tablet Portrait</td>
                  <td rel="Media Query">@media screen and (min-width:601px) and (max-width:950px)</td>
                  <td rel="Left / Right Padding">24px</td>
                </tr>
                <tr>
                  <td rel="Screen Size">Mobile</td>
                  <td rel="Media Query">@media screen and (max-width: 600px)</td>
                  <td rel="Left / Right Padding">16px</td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Layouts;
