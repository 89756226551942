// src/data/aemUpcomingComponentsData.js
const aemUpcomingComponentsData = [
  {
    Component: "Divider",
    Type: "Pattern",
    Usage: "A stylized line (with variations) used to add a visual break in a design. Styling options for type and spacing.",
    figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=11684-21593",
  },
  {
    Component: "Tool tip",
    Type: "Pattern",
    Usage: "A component that can be applied to other components to display more informaion on-hover",
    figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=7711-23528",
  },
  {
    Component: "Carousel - Slide",
    Type: "Pattern",
    Usage: "Pre-configured layout for different slide types (ie. dashboard slide). To be used exclusively with carousel component.",
    figmaLink: "",
  },
];

export default aemUpcomingComponentsData;
