// src/colours/ColoursTier.js

import React from "react";
import { coloursTierData} from '../../../data/coloursData';

const ColoursTier = () => {
  return (
    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Colour</th>
            <th>Hex Value</th>
            <th>Colour Name</th>
            <th>Core Token Name</th>
            <th>Usage</th>
          </tr>
        </thead>
        <tbody>
          {coloursTierData.map((group) => (
            <React.Fragment key={group.name}>
              <tr>
                <th colSpan="5">
                  {group.name}
                  <small>{group.description}</small>
                </th>
              </tr>
              {group.colors.map((color, index) => (
                <tr key={`${group.name}-${index}`}>
                  <td>
                  <div
                    className={`colour-thumb ${color.border ? 'border' : ''}`}
                    style={{ background: color.hex }}
                  ></div>
                  </td>
                  <td rel="Hex">{color.hex}</td>
                  <td rel="Name">{color.colourName}</td>
                  <td rel="Token">{color.coreTokenName}</td>
                  <td rel="Usage">
                    {color.usage}
                    {color.label && (
                      <div className="badge">{color.label}</div>
                    )}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ColoursTier;