// src/pages/patterns/WebAccordions.js

import TitleBar from "../../components/TitleBar";
import Prototype from "../../components/Prototype";

const Accordions = () => {

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Accordions",
    figmaLink: "https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---UI-Elements?node-id=764-3344&t=ur2GsDguaPpKWUXD-4",
  };

  const accordionData = {
    link: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fv6oz764LRzabocmWqiFagF%2F1---UI-Elements%3Fpage-id%3D11161%253A17357%26node-id%3D11161-18359%26viewport%3D894%252C753%252C1.08%26t%3D8USU3AJC9q9JRPTJ-1%26scaling%3Dmin-zoom%26content-scaling%3Dfixed%26starting-point-node-id%3D11161%253A18359",
    title: "Accordions",
    height: "600px",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
        <main>
        
          <div className="columns">
            <div className="col one_half">
              <p className="large">Accordions are used when content needs to be scanned quickly. Our accordions are fluid fill the width of the number of columns they are placed in. Our accordions are focusable and can be expanded and collapsed via keyboard (for accessibility).</p>
            </div>
            <div className="col one_half">
              <strong>Notes</strong>

              <div className="spacer level-1"></div>

              <ul className="bullets">
                <li>Accordions can have a conditional icon before the label</li>
                <li>Expanded accordions have a container that can accomodated any component or layout element (ie. columns, other containers, text etc.).</li>
              </ul>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <Prototype {...accordionData} />
        </main>
    </>
  );
};

export default Accordions;
