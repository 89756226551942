// src/pages/tutorials/WebSettingUpLayouts.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const SettingUpLayouts = () => {

  const titleBarData = {
    eyebrowText: "Tutorials",
    pageTitle: "Setting up layouts",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large"><span className="highlight">In figma</span>, we have set up a library of tokens that match 1:1 what is available in our <span className="highlight alt">AEM library</span>. By using these tokens it ensures that our designs translate exactly from design to development. The tokens are also setup to automatically change their value based on the breakpoint selected. Below is a guide for setting up your Figma files to utilize the token library.</p>
      
      <div className="spacer level-4"></div>

      <div className="numberGroup">
        <div className="col">
          <span className="number">1</span>
        </div>
        <div className="col">
          <h2 className="title-level-2 padding-level-2">Add global components</h2>
          <p>Drop in a header and footer to figma from the UI Elements library and create an auto layout with 0 gap.</p>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-1.png'} alt="" />
        </div>
      </div>

      <div className="spacer level-2"></div>

      <div className="numberGroup">
        <div className="col">
          <span className="number">2</span>
        </div>
        <div className="col">
          <h2 className="title-level-2 padding-level-2">Add a grid to your frame</h2>
          <p>To the parent container choose the correct grid (ie. Desktop Grid).</p>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-2.png'} alt="" />
        </div>
      </div>
      
      <div className="spacer level-2"></div>

      <div className="numberGroup">
        <div className="col">
          <span className="number">3</span>
        </div>
        <div className="col">
          <h2 className="title-level-2 padding-level-2">Create a section with content</h2>
          <p>Start to build out the main content area of the page. For example, add a title component and text with the appropriate styles. Apply auto layout to the group of content. The title component has a built in margin, so when setting the auto layout gap, do not set a gap between title and body text (choose a spacing option variant for the title component).</p>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-3.png'} alt="" />
          
          <div className="spacer level-2"></div>
        </div>      
      </div>

      <div className="numberGroup">
        <div className="col">
          <span className="number">4</span>
        </div>
        <div className="col">

          <h2 className="title-level-2 padding-level-2">Set variables wherever possible</h2>
          <p>Variables for padding and widths are available in Figma that match settings in our CMS (AEM).</p> 

          <p>To the main frame container, use the variable “Breakpoint” for width. There will be only one option - this changes based on the breakpoint.</p>

          <p>For sections (full width areas) we have variables for padding top and bottom (can be independent) and background fills. For sections with content that sits inside the inner container (1140px on desktop) choose “Section/padding-left-right”. Like the frame container variable, there will be only one option available that changes based on the breakpoint. For Containers (an area that sits inside the inner container and can be sized to any set of columns), there are variables for padding (all around), background colour, border radius, box shadow and bottom margin*.</p>

          <p>When setting columns, there is a variable for “gutter” This dynamically changes based on the breakpoint.</p>
          
          <p><strong>Note: if columns stack, change / remove this gutter. For example, the gap on mobile is 11px. If you stack those columns, the gap between the stacked elements is not relevant. The space is based on the component's bottom margin.</strong></p>

          <p className="small">*For Bottom margin, this can be applied by creating an auto layout with a container and content below. For designs with multiple containers beside each other, make sure to include a bottom margin. When the containers stack for tablet or mobile, the gap between them is determined by the bottom margin of the container.</p>
        
          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-4.png'} alt="" />

          <div className="spacer level-1"></div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-6.png'} alt="" />

          <div className="spacer level-1"></div>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-6.png'} alt="" />
        </div>
      </div>

      <div className="spacer level-2"></div>

      <div className="numberGroup">
        <div className="col">
          <span className="number">5</span>
        </div>
        <div className="col">
          <h2 className="title-level-2 padding-level-2">Set variables for variants</h2>

          <p>Some components can be dynamically changed based on breakpoint. These components include, header, footer and title component.</p>
          
          <p>Under variant, set the variable to “Device”. The value will dynamically change based on the breakpoint</p>
        
          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-7.png'} alt="" />
        </div>
      </div>
      
      <div className="spacer level-2"></div>

      <div className="numberGroup">
        <div className="col">
          <span className="number">6</span>
        </div>
        <div className="col">
          <h2 className="title-level-2 padding-level-2">Copy your layout for the next breakpoint + set Appearance variable</h2>

          <p>When you finish your Desktop layout, copy the whole frame. Select the frame and under “Appearance” choose the variable “Role Tokens” > “Device” (ie. Tablet-Landscape).</p>

          <p>If the previous steps are completed correctly, the frame should resize, padding should adjust and component variants should change.</p>

          <p><strong>Note: Grids don't dynamically change. You will need to update the grid for each new breakpoint frame.</strong></p>
        
          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-8.png'} alt="" />
        </div>
      </div>

      <div className="spacer level-2"></div>

      <div className="numberGroup">
        <div className="col">
          <span className="number">7</span>
        </div>
        <div className="col">
          <h2 className="title-level-2 padding-level-2">Conclusion</h2>

          <p>While this process should significantly increase the speed of adapting layouts for different breakpoints, there are still some items that can't be achieved. This includes stacking grids, hiding / showing elements and changing grid orientations still need to be manually done.</p>

          <p>Also, while our CMS is designed to match settings in Figma, there can still be some inconsistencies with spacing which can be ignored. For example, some components have built in margins in AEM (ie buttons have a bottom margin of 24px). When added to a container with padding, the bottom padding will be 24px plus the padding - this is unavoidable in the build.</p>

          <p>A common issue that can occur when setting up your files is that the desktop layout (the frame that your other layouts are built off of) has components with roles set. (ie. the header and footer) Only the frame should have an appearance role set, and only on frames other than Desktop (ie. tablet landscape, tablet portrait and mobile).</p>

          <p>Do your best to use as many variables as possible, but don't feel restricted by this process.</p>

          <img src={process.env.PUBLIC_URL + '/images/tutorials/layout-setup-9.png'} alt="" />
        </div>
      </div>
    </>
  );
};

export default SettingUpLayouts;