// src/data/accessibilityData.js
const aodaAAData = [
    {
        name: "Guideline 1.4: Distinguishable content",
        link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast.html",
        aoda: [
            {
                reference: "Success Criterion 1.4.3 Contrast (Minimum)",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html",
            },
            {
                reference: "Success Criterion 1.4.4 Resize text",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-scale.html",
            },
            {
                reference: "Success Criterion 1.4.5 Images of text",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-text-presentation.html",
            },
        ],
    },
    {
        name: "Guideline 2.4: Distinguishable content",
        link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/navigation-mechanisms.html",
        aoda: [
            {
                reference: "Success Criterion 2.4.5 Multiple ways",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/navigation-mechanisms-mult-loc.html",
            },
            {
                reference: "Success Criterion 2.4.6 Headings and labels",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/navigation-mechanisms-descriptive.html",
            },
            {
                reference: "Success Criterion 2.4.7 Focus visible",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/navigation-mechanisms-focus-visible.html",
            },
        ],
    },
    {
        name: "Guideline 3.1: Readable text content",
        link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/meaning.html",
        aoda: [
            {
                reference: "Success Criterion 3.1.2 Language of parts",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/meaning-other-lang-id.html",
            },
        ],
    },
    {
        name: "Guideline 3.2: Predictable web pages",
        link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior.html",
        aoda: [
            {
                reference: "Success Criterion 3.2.3 Consistent Navigation",
                link: "https://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior-consistent-locations.html",
            },
            {
                reference: "Success Criterion 3.2.4 Consistent identification",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior-consistent-functionality.html",
            },
        ],
    },
    {
        name: "Guideline 3.3: Input assistance",
        link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/minimize-error.html",
        aoda: [
            {
                reference: "Success Criterion 3.3.3 Error suggestion",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/minimize-error-suggestions.html",
            },
            {
                reference: "Success Criterion 3.3.4 Error prevention (Legal, financial, data)",
                link: "http://www.w3.org/TR/UNDERSTANDING-WCAG20/minimize-error-reversible.html",
            },
        ],
    },
];

export { aodaAAData };
