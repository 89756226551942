// src/pages/WebButtons.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";
import Prototype from "../../components/Prototype";

const Buttons = () => {

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'primary', label: 'Primary' },
    { id: 'secondary', label: 'Secondary' },
    { id: 'tertiary', label: 'Tertiary' },
  ];

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Buttons",
    figmaLink: "https://www.figma.com/file/v6oz764LRzabocmWqiFagF/1---UI-Elements?type=design&node-id=764-2515&mode=design&t=sE7Np2yyc3FfJAxy-4",
  };

  const primaryBtnData = {
    link: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fv6oz764LRzabocmWqiFagF%2F1---UI-Elements%3Fpage-id%3D5592%253A14171%26type%3Ddesign%26node-id%3D5592-14175%26viewport%3D513%252C512%252C0.73%26t%3DTlG0m04wh5pA61Gy-1%26scaling%3Dscale-down%26starting-point-node-id%3D5592%253A14175%26hotspot-hints%3D0%26mode%3Ddesign",
    title: "Primary Buttons",
    height: "400px",
  };
  const secondaryBtnData = {
    link: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fv6oz764LRzabocmWqiFagF%2F1---UI-Elements%3Fpage-id%3D5592%253A14171%26type%3Ddesign%26node-id%3D5610-14275%26viewport%3D545%252C314%252C0.18%26t%3DDeo0e7bAImIehnfp-1%26scaling%3Dscale-down%26starting-point-node-id%3D5592%253A14175%26mode%3Ddesign",
    title: "Secondary Buttons",
    height: "400px",
  };

  const tertiaryBtnData = {
    link: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fv6oz764LRzabocmWqiFagF%2F1---UI-Elements%3Fpage-id%3D5592%253A14171%26type%3Ddesign%26node-id%3D5610-14317%26viewport%3D545%252C314%252C0.18%26t%3DQdtY3s0tUSM5vXw9-1%26scaling%3Dscale-down%26starting-point-node-id%3D5592%253A14175%26mode%3Ddesign",
    title: "Tertiary Buttons",
    height: "400px",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <div className="columns reverse">
        <main className="main">
          <h2 id="primary" className="title-level-2 padding-level-2 anchor">Primary Button</h2>
        
          <div className="columns">
            <div className="col one_half">
              <p className="large">Primary action is the most likely choice for users or the guided option. Primary buttons draw attention with a solid fill container. Users usually have access to only one primary button at a time.</p>
            </div>
            <div className="col one_half">
              <strong>Notes</strong>

              <div className="spacer level-1"></div>

              <ul className="bullets">
                <li>Desktop layouts (typically) use the medium size.</li>
                <li><strong>Medium</strong> size is the default button “size”</li>
                <li>For the Tablet and mobile layouts, use the small size</li>
                <li>On mobile, the button spans the full width of the the container it is in</li>
                <li>Buttons are available in <strong>light</strong> and <strong>dark</strong> mode</li>
                <li>When a field is required, the text "required" is added to the supporting text area</li>
              </ul>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <Prototype {...primaryBtnData} />

          <div className="spacer level-4"></div>

          <h2 id="secondary" className="title-level-2 padding-level-2 anchor">Secondary Button</h2>
        
          <div className="columns">
            <div className="col one_half">
              <p className="large">The secondary button can be used in combination with a primary button or on its own. When used beside a primary button, the CTA is a secondary action. Secondary actions provide an alternative to the primary action with less visual emphasis. While the user can find the secondary button, it is not as noticeable as the primary button.</p>
            </div>
            <div className="col one_half">
              <strong>Notes</strong>

              <div className="spacer level-1"></div>

              <ul>
                <li>Desktop layouts (typically) use the medium size.</li>
                <li><strong>Medium</strong> size is the default button “size”</li>
                <li>For the Tablet and mobile layouts, use the small size</li>
                <li>On mobile, the button spans the full width of the the container it is in</li>
                <li>Buttons are available in <strong>light</strong> and <strong>dark</strong> mode</li>
              </ul>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <Prototype {...secondaryBtnData} />

          <div className="spacer level-4"></div>

          <h2 id="tertiary" className="title-level-2 padding-level-2 anchor">Tertiary Button</h2>
        
          <div className="columns">
            <div className="col one_half">
              <p className="large">Tertiary buttons offer additional functionality for the user to access. They are designed to be subtle yet still easily perceivable by the user. They are not typically placed beside a primary or seconary button, but rather below. They are commonly used with the pairing of a primary or secondar button.</p>
            </div>
            <div className="col one_half">
              <strong>Notes</strong>

              <div className="spacer level-1"></div>

              <ul>
                <li>Desktop layouts (typically) use the medium size.</li>
                <li><strong>Medium</strong> size is the default button “size”</li>
                <li>For the Tablet and mobile layouts, use the small size</li>
                <li>Buttons are available in <strong>light</strong> and <strong>dark</strong> mode</li>
              </ul>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <Prototype {...tertiaryBtnData} />

        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Buttons;
