// src/components/AppNav.js
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const AppNav = ({ isMenuActive }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (accordion) => {
    setActiveAccordion(activeAccordion === accordion ? null : accordion);
  };

  const scrollToTop = () => {
    const container = document.querySelector(".content");

    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "instant",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  const scrollCollapse = () => {
    scrollToTop();
  };

  const renderNavLink = (to, text) => (
    <li key={to}>
      <NavLink
        className={`navbar-item ${activeAccordion === to ? "is-active" : ""}`}
        activeClassName="is-active"
        to={to}
        onClick={scrollCollapse}
      >
        {text}
      </NavLink>
    </li>
  );

  return (
    <nav className={`nav ${isMenuActive ? 'active' : ''}`} role="navigation" aria-label="main navigation">
      <ul>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "overview" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("overview")}
          >
            <NavLink 
              to="/app-home" 
              className="title-level-3 overview"
              onClick={scrollCollapse}
            >
              Overview
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "overview" ? "is-active" : ""}`}>
            {renderNavLink("/app-home", "Welcome")}
            {renderNavLink("/app-releases", "Releases")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "foundation" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("foundation")}
          >
            <NavLink 
              to="/app-foundation" 
              className="title-level-3 foundation"
              onClick={scrollCollapse}
            >
              Foundation
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "foundation" ? "is-active" : ""}`}>
            {renderNavLink("/app-colour-system", "Colour system")}
            {renderNavLink("/app-typography", "Typography")}
            {renderNavLink("/app-grids", "Grids")}
            {renderNavLink("/app-layout", "Layout")}
            {renderNavLink("/app-elevation", "Elevation")}
            {renderNavLink("/app-border-radius", "Radius")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "patterns" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("patterns")}
          >
            <NavLink 
              to="/app-patterns" 
              className="title-level-3 patterns"
              onClick={scrollCollapse}
            >
              Patterns
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "patterns" ? "is-active" : ""}`}>
            {renderNavLink("/app-flutter-components", "Flutter Components")}
            {renderNavLink("/app-native-components", "Native Components")}
            {renderNavLink("/app-amp", "AMP Ads")}
            {renderNavLink("/app-icons", "Icons")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "tokens" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("tokens")}
          >
            <NavLink 
              to="/app-tokens" 
              className="title-level-3 tokens"
              onClick={scrollCollapse}
            >
              Tokens
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "tokens" ? "is-active" : ""}`}>
            {renderNavLink("/app-token-values", "Values")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "accessibility" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("accessibility")}
          >
            <NavLink 
              to="/app-accessibility" 
              className="title-level-3 accessibility"
              onClick={scrollCollapse}
            >
              Accessibility
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "accessibility" ? "is-active" : ""}`}>
            {renderNavLink("/app-accessibility-overview", "Overview")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "brand" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("brand")}
          >
            <NavLink 
              to="/app-brand" 
              className="title-level-3 brand"
              onClick={scrollCollapse}
            >
              Brand
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "brand" ? "is-active" : ""}`}>
            { /* renderNavLink("/app-brand-overview", "Overview") */}
            { /* renderNavLink("/app-brand-voice", "Voice") */}
            { /* renderNavLink("/app-brand-logo#overview", "Logo") */}
            { /* renderNavLink("/app-brand-colour-palette", "Colour palette") */}
            { /* renderNavLink("/app-brand-typography", "Typography") */}
            { /* renderNavLink("/app-brand-imagery", "Imagery") */}
            {renderNavLink("/app-brand-glossary", "Glossary")}         
          </ul>
        </li>
        <li className="back">
          <NavLink 
            to="/web-home" 
            onClick={scrollCollapse}
          >
            Web Design System
          </NavLink>
          </li>
      </ul>
    </nav>
  );
};

export default AppNav;
