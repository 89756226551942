// src/pages/WebColour.js
import React, { useState } from "react";
import ColoursFunctional from "./colours/ColoursFunctional";
import ColoursBrand from "./colours/ColoursBrand";
import ColoursTier from "./colours/ColoursTier";

import TitleBar from "../../components/TitleBar";

const Colour = () => {
  const [activeTab, setActiveTab] = useState("Functional");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Functional":
        return <ColoursFunctional />;
      case "Brand":
        return <ColoursBrand />;  
      case "Tier":
        return <ColoursTier />;
      default:
        return null;
    }
  };

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Colour system",
    figmaLink: "https://www.figma.com/file/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?type=design&node-id=30%3A28&mode=design&t=fC2BOgwAsb9yQsjp-1",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">
        Composed of functional, brand, tier, gradient and colour patterns, below is a breakdown of
        colour scale, token names and usage guidelines.
      </p>

      <div className="spacer level-2"></div>

      <div className="tabsContainer">
        <nav className="tabs">
          <ul>
            <li
              className={activeTab === "Functional" ? "active" : ""}
              onClick={() => handleTabClick("Functional")}
            >
              Functional
            </li>
            <li
              className={activeTab === "Brand" ? "active" : ""}
              onClick={() => handleTabClick("Brand")}
            >
              Brand
            </li>
            <li
              className={activeTab === "Tier" ? "active" : ""}
              onClick={() => handleTabClick("Tier")}
            >
              Tier
            </li>
          </ul>
        </nav>
      </div>

      {renderTabContent()}
    </>
  );
};

export default Colour;