// src/data/typographyData.js
const desktopTypographyData = [
    {
        name: "H1 - Large",
        typeface: "Work Sans",
        weight: "800",
        size: "52px / 125%", 
        usage: "Large Hero banners",
        class: "title-level-1-large",
    },
    {
        name: "H1",
        typeface: "Work Sans",
        weight: "800",
        size: "42px / 125%", 
        usage: "Hero banners",
        class: "title-level-1",
    },
    {
        name: "H1 - Small",
        typeface: "Work Sans",
        weight: "800",
        size: "38px / 125%", 
        usage: "General Pages",
        class: "title-level-1-small",
    },
    {
        name: "H2 - XLarge",
        typeface: "Work Sans",
        weight: "800",
        size: "52px / 125%", 
        usage: "Section titles where content is an isolated experience",
        class: "title-level-2-x-large",
    },
    {
        name: "H2 - Large",
        typeface: "Work Sans",
        weight: "800",
        size: "38px / 125%", 
        usage: "Section titles with small amount content",
        class: "title-level-2-large",
    },
    {
        name: "H2",
        typeface: "Work Sans",
        weight: "700",
        size: "30px / 125%", 
        usage: "Sub section title for copy heavy pages. A custom title style exists that is not wrapped in an <h2> but is styled as one. This is used for Carousels of content that sit above and <h1> (IE. Home page carousel)",
        class: "title-level-2",
        label: "new"
    },
    {
        name: "H2 - Small",
        typeface: "Work Sans",
        weight: "700",
        size: "24px / 150%", 
        usage: "Sub section titles",
        class: "title-level-2-small",
    },
    {
        name: "H2 - XSmall",
        typeface: "Work Sans",
        weight: "700",
        size: "20px / 150%", 
        usage: "Sub section titles",
        class: "title-level-2-x-small",
    },
    {
        name: "H3 - Large",
        typeface: "Work Sans",
        weight: "700",
        size: "20px / 150%", 
        usage: "Cards and content in few columns",
        class: "title-level-3-large",
    },
    {
        name: "H3",
        typeface: "Work Sans",
        weight: "700",
        size: "18px / 150%", 
        usage: "Cards and content in many columns",
        class: "title-level-3",
    },
    {
        name: "H3 - Small",
        typeface: "Work Sans",
        weight: "700",
        size: "16px / 150%", 
        usage: "Buttons, cards and content blocks in condensed areas",
        class: "title-level-3-small",
    },
    {
        name: "H4",
        typeface: "Work Sans",
        weight: "700",
        size: "16px / 150%", 
        usage: "Level 4 headings",
        class: "title-level-4",
    },
    {
        name: "H5",
        typeface: "Work Sans",
        weight: "700",
        size: "14px / 150%", 
        usage: "Level 5 headings",
        class: "title-level-5",
    },
    {
        name: "H6",
        typeface: "Work Sans",
        weight: "700",
        size: "12px / 150%", 
        usage: "Level 6 headings",
        class: "title-level-6",
    },
  ];

  const tabletTypographyData = [
    {
        name: "H1 - Large",
        typeface: "Work Sans",
        weight: "800",
        size: "48px / 125%", 
        usage: "Large Hero banners",
        class: "tablet title-level-1-large",
    },
    {
        name: "H1",
        typeface: "Work Sans",
        weight: "800",
        size: "42px / 125%", 
        usage: "Hero banners",
        class: "tablet title-level-1",
    },
    {
        name: "H1 - Small",
        typeface: "Work Sans",
        weight: "800",
        size: "36px / 125%", 
        usage: "General Pages",
        class: "tablet title-level-1-small",
    },
    {
        name: "H2 - XLarge",
        typeface: "Work Sans",
        weight: "800",
        size: "42px / 125%", 
        usage: "Section titles where content is an isolated experience",
        class: "tablet title-level-2-x-large",
    },
    {
        name: "H2 - Large",
        typeface: "Work Sans",
        weight: "800",
        size: "36px / 125%", 
        usage: "Section titles with small amount content",
        class: "tablet title-level-2-large",
    },
    {
        name: "H2",
        typeface: "Work Sans",
        weight: "700",
        size: "30px / 125%", 
        usage: "Sub section title for copy heavy pages. A custom title style exists that is not wrapped in an <h2> but is styled as one. This is used for Carousels of content that sit above and <h1> (IE. Home page carousel)",
        class: "tablet title-level-2",
        label: "new"
    },
    {
        name: "H2 - Small",
        typeface: "Work Sans",
        weight: "700",
        size: "24px / 125%", 
        usage: "Sub section titles",
        class: "tablet title-level-2-small",
    },
    {
        name: "H2 - XSmall",
        typeface: "Work Sans",
        weight: "700",
        size: "20px / 125%", 
        usage: "Sub section titles",
        class: "tablet title-level-2-x-small",
    },
    {
        name: "H3 - Large",
        typeface: "Work Sans",
        weight: "700",
        size: "20px / 125%", 
        usage: "Cards and content in few columns",
        class: "tablet title-level-3-large",
    },
    {
        name: "H3",
        typeface: "Work Sans",
        weight: "700",
        size: "18px / 150%", 
        usage: "Cards and content in many columns",
        class: "tablet title-level-3",
    },
    {
        name: "H3 - Small",
        typeface: "Work Sans",
        weight: "700",
        size: "16px / 125%", 
        usage: "Buttons, cards and content blocks in condensed areas",
        class: "tablet title-level-3-small",
    },
    {
        name: "H4",
        typeface: "Work Sans",
        weight: "700",
        size: "16px / 125%", 
        usage: "Level 4 headings",
        class: "tablet title-level-4",
    },
    {
        name: "H5",
        typeface: "Work Sans",
        weight: "700",
        size: "14px / 125%", 
        usage: "Level 5 headings",
        class: "tablet title-level-5",
    },
    {
        name: "H6",
        typeface: "Work Sans",
        weight: "700",
        size: "12px / 125%", 
        usage: "Level 6 headings",
        class: "tablet title-level-6",
    },
  ];

  const mobileTypographyData = [
    {
        name: "H1 - Large",
        typeface: "Work Sans",
        weight: "800",
        size: "40px / 125%", 
        usage: "Large Hero banners",
        class: "mobile title-level-1-large",
    },
    {
        name: "H1",
        typeface: "Work Sans",
        weight: "800",
        size: "36px / 125%", 
        usage: "Hero banners",
        class: "mobile title-level-1",
    },
    {
        name: "H1 - Small",
        typeface: "Work Sans",
        weight: "800",
        size: "30px / 125%", 
        usage: "General Pages",
        class: "mobile title-level-1-small",
    },
    {
        name: "H2 - XLarge",
        typeface: "Work Sans",
        weight: "800",
        size: "36px / 125%", 
        usage: "Section titles where content is an isolated experience",
        class: "mobile title-level-2-x-large",
    },
    {
        name: "H2 - Large",
        typeface: "Work Sans",
        weight: "800",
        size: "30px / 125%", 
        usage: "Section titles with small amount content",
        class: "mobile title-level-2-large",
    },
    {
        name: "H2",
        typeface: "Work Sans",
        weight: "700",
        size: "24px / 125%", 
        usage: "Sub section title for copy heavy pages. A custom title style exists that is not wrapped in an <h2> but is styled as one. This is used for Carousels of content that sit above and <h1> (IE. Home page carousel)",
        class: "mobile title-level-2",
        label: "new"
    },
    {
        name: "H2 - Small",
        typeface: "Work Sans",
        weight: "700",
        size: "20px / 125%", 
        usage: "Sub section titles",
        class: "mobile title-level-2-small",
    },
    {
        name: "H2 - XSmall",
        typeface: "Work Sans",
        weight: "700",
        size: "18px / 125%", 
        usage: "Sub section titles",
        class: "mobile title-level-2-x-small",
    },
    {
        name: "H3 - Large",
        typeface: "Work Sans",
        weight: "700",
        size: "20px / 125%", 
        usage: "Cards and content in few columns",
        class: "mobile title-level-3-large",
    },
    {
        name: "H3",
        typeface: "Work Sans",
        weight: "700",
        size: "18px / 150%", 
        usage: "Cards and content in many columns",
        class: "mobile title-level-3",
    },
    {
        name: "H3 - Small",
        typeface: "Work Sans",
        weight: "700",
        size: "16px / 125%", 
        usage: "Buttons, cards and content blocks in condensed areas",
        class: "mobile title-level-3-small",
    },
    {
        name: "H4",
        typeface: "Work Sans",
        weight: "700",
        size: "16px / 125%", 
        usage: "Level 4 headings",
        class: "mobile title-level-4",
    },
    {
        name: "H5",
        typeface: "Work Sans",
        weight: "700",
        size: "14px / 125%", 
        usage: "Level 5 headings",
        class: "mobile title-level-5",
    },
    {
        name: "H6",
        typeface: "Work Sans",
        weight: "700",
        size: "12px / 125%", 
        usage: "Level 6 headings",
        class: "mobile title-level-6",
    },
  ];  

  const bodyTypographyData = [
    {
        name: "Body - XSmall",
        typeface: "Open Sans",
        weight: "400 & 700",
        size: "12px / 150%",
        spacing: "0", 
        usage: "Legal copy",
        class: "xsmall",
    },
    {
        name: "Body - Small",
        typeface: "Open Sans",
        weight: "400 & 700",
        size: "14px / 150%",
        spacing: "0", 
        usage: "Some Cards, Pills",
        class: "small",
    },
    {
        name: "Body",
        typeface: "Open Sans",
        weight: "400 & 700",
        size: "16px / 150%",
        spacing: "0", 
        usage: "Body copy",
        class: "default",
    },
    {
        name: "Body - Large",
        typeface: "Open Sans",
        weight: "400 & 700",
        size: "20px / 150%",
        spacing: "0", 
        usage: "Paragraph text in most Hero areas & Callouts. NOTE: Font size reduces to 16px on mobile",
        class: "large",
    },
  ];

  const specialTypographyData = [
    {
        name: "Eyebrow",
        typeface: "Work Sans",
        weight: "700",
        size: "12px / 150%",
        spacing: "50%", 
        usage: "Eyebrow text - use sapphire-900 on white, and light-blue backgrounds, midnight-700 on medium-blue backgrounds and white on dark-blue and darkest-blue background. There is also a style option for the <h1> title to appear as an Eyebrow for SEO purposes.",
        class: "paragraph-special-type-eyebrow",
        label: "new"
    },
    {
        name: "Eyebrow Slim",
        typeface: "Work Sans",
        weight: "700",
        size: "12px / 150%",
        spacing: "10%", 
        usage: "Eyebrow text, Buttons - use sapphire-900 on white, and light-blue backgrounds, midnight-700 on medium-blue backgrounds and white on dark-blue and darkest-blue background",
        class: "paragraph-special-type-eyebrow-slim",
    }
  ];

  const appTypographyData = [
    {
      name: "Headlines",
      description: "Are best-suited for short, high-emphasis text on smaller screens. These styles can be good for marking primary passages of text or important regions of content.",
      typography: [
        {
            name: "Headline Large",
            typeface: "Work Sans",
            weight: "700",
            size: "32px / 40px",
            spacing: "-0.4px", 
            usage: "X-Large headlines",
            class: "app-headline-large",
        },
        {
            name: "Headline Medium",
            typeface: "Work Sans",
            weight: "700",
            size: "28px / 36px",
            spacing: "-0.4px", 
            usage: "Large headlines, Main page titles",
            class: "app-headline-medium",
        },
        {
            name: "Headline Small",
            typeface: "Work Sans",
            weight: "700",
            size: "24px / 32px",
            spacing: "-0.4px", 
            usage: "Headlines",
            class: "app-headline-small",
        },
      ],
    },
    {
    name: "Subheadings",
    description: "Are smaller than headline styles, and should be used for medium-emphasis text that remains relatively short. For example, consider using title styles to divide secondary passages of text or secondary regions of content.",
    typography: [
        {
            name: "Subheading Large",
            typeface: "Work Sans",
            weight: "500 & 700",
            size: "22px / 28px",
            spacing: "-0.2px", 
            usage: "Subheads/Labels",
            class: "app-title-large",
        },
        {
            name: "Subheading Medium",
            typeface: "Work Sans",
            weight: "500 & 700",
            size: "18px / 24px",
            spacing: "-0.2px", 
            usage: "Subheads/Labels",
            class: "app-title-medium",
        },
        {
            name: "Subheading Small",
            typeface: "Work Sans",
            weight: "500 & 700",
            size: "16px / 24px",
            spacing: "-0.2px", 
            usage: "Subheads/Labels",
            class: "app-title-small",
        },
    ],
    },
    {
    name: "Body",
    description: "Styles are used for longer passages of text in your app, which are readable at smaller sizes and can be comfortably read in longer passages.",
    typography: [
        {
            name: "Body X-Large",
            typeface: "Open Sans",
            weight: "400 & 600",
            size: "18px / 24px",
            spacing: "0px", 
            usage: "Body text",
            class: "app-body-x-large",
        },
        {
            name: "Body Large",
            typeface: "Open Sans",
            weight: "400 & 600",
            size: "16px / 24px",
            spacing: "0px", 
            usage: "Body text",
            class: "app-body-large",
        },
        {
            name: "Body Medium",
            typeface: "Open Sans",
            weight: "400 & 600",
            size: "14px / 20px",
            spacing: "0px", 
            usage: "Body text",
            class: "app-body-medium",
        },
        {
            name: "Body Small",
            typeface: "Open Sans",
            weight: "400 & 600",
            size: "12px / 16px",
            spacing: "0px", 
            usage: "Small text, Legal copy",
            class: "app-body-small",
        },
        {
            name: "Body X-Small",
            typeface: "Open Sans",
            weight: "400 & 600",
            size: "10px / 14px",
            spacing: "0px", 
            usage: "Receipts total",
            class: "app-body-x-small",
        },  
    ],
    },
    {
    name: "Labels",
    description: "Styles are smaller, utilitarian styles, used for things like the text inside components or for very small text in the content body, such as captions. Buttons, for example, use the label large style.",
    typography: [
        {
            name: "Label Large",
            typeface: "Work Sans",
            weight: "600",
            size: "14px / 20px",
            spacing: "1px", 
            usage: "Buttons, Action Labels",
            class: "app-label-large",
        },
        {
            name: "Label Medium",
            typeface: "Work Sans",
            weight: "600",
            size: "12px / 16px",
            spacing: "1px", 
            usage: "Buttons, Action Labels",
            class: "app-label-medium",
        },
        {
            name: "Label Small",
            typeface: "Work Sans",
            weight: "400 & 600",
            size: "10px / 12px",
            spacing: "0px", 
            usage: "Navigation",
            class: "app-label-small",
        },
      ],
    },
    {
        name: "Other",
        description: "",
        typography: [
            {
                name: "Caption",
                typeface: "Open Sans",
                weight: "400",
                size: "12px / 16px",
                spacing: "1px", 
                usage: "Caption labels",
                class: "app-other-caption",
            },
            {
                name: "Fine Print",
                typeface: "Open Sans",
                weight: "400",
                size: "13px / 16px",
                spacing: "0px", 
                usage: "Fine Print",
                class: "app-other-fine-print",
            },
          ],
        },
  ]

  export { desktopTypographyData, tabletTypographyData, mobileTypographyData, appTypographyData, bodyTypographyData, specialTypographyData };
