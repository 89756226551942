// src/pages/accessibility/WebAccessibility.js
import React from "react";
import { webAccessibilityData } from '../../data/landingPageData';
import { NavLink } from "react-router-dom";

import TitleBar from "../../components/TitleBar";

const Accessibility = () => {

  const titleBarData = {
    pageTitle: "Accessibility",
   };

   const scrollToTop = () => {
    const container = document.querySelector(".content");

    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "instant",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">

      </p>

      <div className="landingPageGrid foundation">
      {webAccessibilityData.map((item, index) => (
          <div className="tile" key={index}>
            <div>
              <span className="title-level-3-large padding-level-1">{item.title}</span>
              <p className="small">{item.description}</p>
            </div>
            <NavLink onClick={scrollToTop} to={item.link} className="btn tertiary"> Explore</NavLink>
          </div>
      ))}
      </div>
    </>
  );
};

export default Accessibility;