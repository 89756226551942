// src/pages/AppTypography.js
import React, { } from "react";
import TypographyApp from "../typography/TypographyApp";

import TitleBar from "../../components/TitleBar";

const Typography = () => {
  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Typography",
    figmaLink: "https://www.figma.com/file/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?type=design&node-id=2235-1901&mode=design"
  };

  return (
    <>
      <TitleBar {...titleBarData} />
        
      <p className="large">We use two typefaces in our design system: <span className="highlight">"Work Sans"</span> for headings, subheadings and labels. <span className="highlight alt">"Open Sans"</span> for body copy (all sizes).</p>
  
      <TypographyApp />
    </>
  );
};

export default Typography;