// src/components/TitleBar.js
import React from 'react';

export default function TitleBar({ eyebrowText, pageTitle, figmaLink }) {
  return (
    <div className="titleBar" id="titleBar">
      <span className="paragraph-special-type-eyebrow">{eyebrowText}</span>
      <div className="columns">
        <div className="col">
          <h2 className="title-level-2-large padding-level-2">{pageTitle}</h2>
        </div>
        <div className="col">
          {/* Conditionally render Figma link */}
          {figmaLink && (
            <a
              href={figmaLink}
              rel="noreferrer"
              target="_blank"
              className="figma-btn"
            >
              View in Figma
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
