// src/colours/glossaryData.js

// GLOSSARY
const glossaryData = [
    {
        term: "(Cash/Dream) account",
        definition: "where Reward Miles are deposited once a collector earns them.",
    },
    {
        term: "(collector) account",
        definition: "all of a collector's program information, including profile, balances and number.",
    },
    {
        term: "AIR MILES (Reward Program)",
        definition: "Your loyalty program.",
    },
    {
        term: "ambassadors",
        definition: "title of AIR MILES representatives assisting collectors in customer care.",
    },
    {
        term: "AIR MILES app",
        definition: "the AIR MILES smartphone application; available through the App Store, Google Play and the Galaxy Store.",
    },
    {
        term: "balance preference",
        definition: "the ratio of how Reward Miles collected are deposited.",
    },
    {
        term: "Blue",
        definition: "the base all collectors start at until they qualify for Gold or Onyx status.",
    },
    {
        term: "Bonus Miles",
        definition: "Miles obtained through the successful completion of time-limited bonus offers.",
    },
    {
        term: "bonus offer",
        definition: "a way to earn extra Miles in addition to partner base offerings. Time limited and dependent on terms and criteria being met.",
    },
    {
        term: "(AIR MILES) card",
        definition: "the physical or digitally-scannable collector ID card to be presented at partners.",
    },
    {
        term: "(AIR MILES) Cash",
        definition: "A rewards segment that allows collectors to use 95 Cash Miles for $10 towards purchases.",
    },
    {
        term: "(AIR MILES) Cash Miles",
        definition: "Reward Miles in a collector's Cash account that can be used towards Cash rewards.",
    },
    {
        term: "Cash rewards",
        definition: "rewards that are available by using Cash Miles in-store or online.",
    },
    {
        term: "reward (catalogue)",
        definition: "the online directory of merchandise, entertainment, and Cash rewards available to collectors available at rewards.airmiles.ca.",
    },
    {
        term: "collector",
        definition: "someone enrolled in the AIR MILES Reward Program.",
    },
    {
        term: "collector number",
        definition: "the 11 digits appearing on a collector's AIR MILES card.",
    },
    {
        term: "(AIR MILES) credit card",
        definition: "any credit card offered by our financial partners that allows a collector to earn Reward Miles on every purchase.",
    },
    {
        term: "Customer care",
        definition: "our collector support department available by phone, live chat and social media.",
    },
    {
        term: "(AIR MILES) Dream",
        definition: "A rewards segment that allows collectors to use Dream Miles towards, travel, merchandise, leisure activities and events.",
    },
    {
        term: "(AIR MILES) Dream Miles",
        definition: "Reward Miles in a collector's Dream account that can be used towards Dream rewards from the rewards catalogue or the personal shopper service (Onyx only).",
    },
    {
        term: "Dream rewards",
        definition: "rewards that are available by using Dream Miles online.",
    },
    {
        term: "evoucher",
        definition: "online Cash reward (not redeemed for in-store).",
    },
    {
        term: "Gold",
        definition: "a status level for any collector that has accumulated a certain amount of Reward Miles in a calendar year or met another qualification requirement. This status unlocks discounts and enhanced benefits.",
    },
    {
        term: "offers",
        definition: "ways to get Reward Miles from a partner, either through always-on base offers, or time-limited bonus offers.",
    },
    {
        term: "Onyx",
        definition: "a status level for any collector that has accumulated a certain amount of Reward Miles in a calendar year or met another qualification requirement. This status unlocks deeper discounts than Gold, as well as added benefits.",
    },
    {
        term: "partners",
        definition: "Partners are at the forefront of the collector experience. They are the only time collectors have a tangible experience with the AIR MILES brand. Beyond getting Miles, some also create the feeling of instant gratification when collectors get free stuff with AIR MILES Cash.",
    },
    {
        term: "personal shopper",
        definition: "service offered to Onyx collectors where they can use Dream Miles towards rewards not found in the rewards catalogue.",
    },
    {
        term: "profile",
        definition: "the portion of a collector's account containing their personal information.",
    },
    {
        term: "Rewards",
        definition: "anything available to collectors by using either Cash Miles or Dream Miles",
    },
    {
        term: "(AIR MILES) Reward Miles",
        definition: "the currency of the AIR MILES Reward Program, issued by partners or through AIR MILES promotions. Once awarded to a collector, they become Cash Miles or Dream Miles, depending on the account they are deposited into.",
    },
    {
        term: "status",
        definition: "the three levels of the AIR MILES Reward Program that a collector can qualify for: Blue, Gold, and Onyx.",
    },
];

export { glossaryData };