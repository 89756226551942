// src/pages/AppRadius.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Radius = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Border radius",
    figmaLink: "https://www.figma.com/file/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?type=design&node-id=2235-1901&mode=design"
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Coming soon.</p>
    </>
  );
};

export default Radius;
