// src/pages/WebaemComponents.js
import React from "react";

import TitleBar from "../../components/TitleBar";
import aemComponentsData from "../../data/aemComponentsData";
import aemUpcomingComponentsData from "../../data/aemUpcomingComponentsData";

const AEMComponents = () => {

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "AEM Components",
    figmaLink: "",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">We use <span className="highlight">AEM</span> as our <span className="highlight alt">CMS</span> for Air Miles web, we utilize AEM's core components as well as develop custom components. Below is a breakdown of what components are available in AEM.</p>
    
      <h2 className="title-level-2 padding-level-2">Available Components</h2>

      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th>Component</th>
              <th>Usage</th>
              <th>Type</th>
              <th>Design</th>
            </tr>
          </thead>
          <tbody>
          {aemComponentsData.map((item, index) => (
            <tr key={index}>
                <td rel="Component">{item.Component}</td>
                <td rel="Usage">{item.Usage}</td>
                <td rel="Type">{item.Type}</td>
                <td rel="Design">
                {/* Conditionally render Figma link */}
                {item.figmaLink && (
                  <a
                    href={item.figmaLink}
                    rel="noreferrer"
                    target="_blank"
                    className="figma-btn"
                  >
                    View in Figma
                  </a>
                )}  
                </td>     
            </tr>
          ))}
          </tbody>
        </table>
      </div>

      <div className="spacer level-4"></div>
    
      <h2 className="title-level-2 padding-level-2">Upcoming Components</h2>

      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th>Component</th>
              <th>Usage</th>
              <th>Type</th>
              <th>Design</th>
            </tr>
          </thead>
          <tbody>
          {aemUpcomingComponentsData.map((item, index) => (
            <tr key={index}>
                <td rel="Component">{item.Component}</td>
                <td rel="Usage">{item.Usage}</td>
                <td rel="Type">{item.Type}</td>  
                <td rel="Design">
                {/* Conditionally render Figma link */}
                {item.figmaLink && (
                  <a
                    href={item.figmaLink}
                    rel="noreferrer"
                    target="_blank"
                    className="figma-btn"
                  >
                    View in Figma
                  </a>
                )}  
                </td>      
            </tr>
          ))}
          </tbody>
        </table>
      </div>

    </>
  );
};

export default AEMComponents;