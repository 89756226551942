// src/pages/WebNewPageChecklist.js
import React from "react";

import TitleBar from "../../components/TitleBar";

import { PageChecklistData } from '../../data/checklistData';

const CheckList = () => {

  const titleBarData = {
    eyebrowText: "Resources",
    pageTitle: "New page checklist",
  };

  let counter = 0;

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">The following checks should be made for <strong><em>every new page prior to launch</em></strong>. These are in place to ensure proper <strong>SEO, on-site search</strong> and <strong>Information Architecture</strong> of our webpages.</p>
      
      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th></th>
              <th>Task</th>
              <th>Type</th>
              <th>Groups Involved</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {PageChecklistData.map((item, index) => (
              <tr key={index}>
                <td rel="Step">{++counter}</td>
                <td rel="Task">{item.task}</td>
                <td rel="Task">{item.type}</td>
                <td rel="Groups Involved">{item.groups}</td>
                <td rel="Details" >{item.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CheckList;