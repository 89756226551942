// src/pages/AppElevation.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Elevation = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Elevation",
    figmaLink: ""
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Coming soon.</p>
    </>
  );
};

export default Elevation;
