// src/typography/TypographyApp.js
import React from "react";
import { appTypographyData } from '../../data/typographyData';

const TypographyApp = () => {
  return (
    <>
      <h2 id="webApp" className="title-level-2 padding-level-2 anchor">Typography Definitions</h2>
      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th>Name</th>
              <th>Typeface</th>
              <th>Weight</th>
              <th>Size</th>
              <th>Usage</th>
            </tr>
          </thead>
          <tbody>
            {appTypographyData.map((group) => (
              <React.Fragment key={group.name}>
                <tr>
                  <th colSpan="5">
                    {group.name}
                    <small>{group.description}</small>
                  </th>
                </tr>
                {group.typography.map((typography, index) => (
                  <tr key={`${group.name}-${index}`}>
                    <td rel="Name"><span className={typography.class}>{typography.name}</span></td>
                    <td rel="Typeface">{typography.typeface}</td>
                    <td rel="Weight">{typography.weight}</td>
                    <td rel="Size">{typography.size}</td>
                    <td rel="Usage">{typography.usage}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TypographyApp;