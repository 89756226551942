// src/components/GlobalHeader.js
import React, { } from 'react';
import ChangeLogData from "../data/changeLogData";

export default function Header({ isMenuActive, toggleMenu }) {

  // Get the latest version from the ChangeLogData
  const latestVersion = ChangeLogData.length > 0 ? ChangeLogData[0].version : '';

  return (
    <header className="header">
        <img src={process.env.PUBLIC_URL + '/images/logo-full.svg'} className='logo-full' alt='AIR MILES logo' />
        
        <div className='columns'>
          <span className='small version'>Version {latestVersion}</span>
        </div>
    </header>
  );
}
