// src/pages/Releases.js
import ChangeLog from "../../components/ChangeLog";

import TitleBar from "../../components/TitleBar";

import ChangeLogData from "../../data/changeLogData";

const Releases = () => {

  const titleBarData = {
    eyebrowText: "Overview",
    pageTitle: "Releases",
  };

  return (
    <>
      <TitleBar {...titleBarData} />

      <h2 id="changeLog" className="title-level-2 padding-level-1 anchor">Change log</h2>
      <ChangeLog data={ChangeLogData} />
    </>
  );
};

export default Releases;