// src/pages/AppLayouts.js
import React, { } from "react";

import TitleBar from "../../components/TitleBar";

const Layouts = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Layout",
    figmaLink: "https://www.figma.com/file/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?type=design&node-id=684%3A4199&mode=design&t=P5HO8TYxCouYMPE8-1",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Coming soon.</p>
    </>
  );
};

export default Layouts;
