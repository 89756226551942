// src/pages/AccessibilityOverview.js
import React from "react";
import { aodaAAData } from '../../data/accessibilityData';

import TitleBar from "../../components/TitleBar";

const Buttons = () => {

  const titleBarData = {
    eyebrowText: "",
    pageTitle: "Accessibility",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">As of January 1, 2021, the <a href="https://www.ontario.ca/laws/statute/05a11" target="_blank" rel="noreferrer">AODA</a> requires all public websites be accsessible. AIR MILES is committed to meeting the WCAG 2.0 Level AA success criteria.</p>
      <div className="spacer level-1"></div>

      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <tbody>
          {aodaAAData.map((group) => (
            <React.Fragment key={group.name}>
              <tr>
                <th>
                {group.name}
                <small><a href={group.link} target="_blank" rel="noreferrer">Read documentation</a></small>
                </th>
              </tr>
              {group.aoda.map((aoda, index) => (
                <tr key={`${group.name}-${index}`}>
                  <td>
                    <a href={aoda.link} target="_blank" rel="noreferrer">{aoda.reference}</a>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
          </tbody>
        </table>

        <div className="spacer level-2"></div>

        <p>Read <a href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/intro.html" target="_blank" rel="noreferrer">Introduction to Understanding WCAG 2.0</a> for additional information.</p>

      </div>

    </>
  );
};

export default Buttons;
