// src/pages/AppTokens.js
import React, { useState } from "react";

import AppTokensColours from "./AppTokensColours";
import AppTokensTypography from "./AppTokensTypography";
import AppTokensSizing from "./AppTokensSizing";
import AppTokensElevation from "./AppTokensElevation";
import AppTokensBorderRadius from "./AppTokensBorderRadius";

import TitleBar from "../../components/TitleBar";

const Tokens = () => {
  const [activeTab, setActiveTab] = useState("Colours");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Colours":
        return <AppTokensColours />;
      case "Typography":
        return <AppTokensTypography />;
      case "Sizing":
          return <AppTokensSizing />;
      case "Elevation":
          return <AppTokensElevation />; 
      case "Border Radius":
          return <AppTokensBorderRadius />;     
      default:
        return null;
    }
  };

  const titleBarData = {
    eyebrowText: "",
    pageTitle: "Tokens",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Tokens are used for the compostion of our components. They ensure consistent variables are cascaded across patterns and layouts.</p>

      <div className="spacer level-2"></div>

      <div className="tabsContainer">
        <nav className="tabs">
          <ul>
            <li
              className={activeTab === "Colours" ? "active" : ""}
              onClick={() => handleTabClick("Colours")}
            >
              Colours
            </li>
            <li
              className={activeTab === "Typography" ? "active" : ""}
              onClick={() => handleTabClick("Typography")}
            >
              Typography
            </li>
            <li
              className={activeTab === "Sizing" ? "active" : ""}
              onClick={() => handleTabClick("Sizing")}
            >
              Sizing
            </li>
            <li
              className={activeTab === "Elevation" ? "active" : ""}
              onClick={() => handleTabClick("Elevation")}
            >
              Elevation
            </li>
            <li
              className={activeTab === "Border Radius" ? "active" : ""}
              onClick={() => handleTabClick("Border Radius")}
            >
              Border Radius
            </li>
          </ul>
        </nav>
      </div>
      
      {renderTabContent()}

    </>
  );
};

export default Tokens;
