// src/pages/WebForms.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";
import Prototype from "../../components/Prototype";

const Forms = () => {

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'inputs', label: 'Inputs' },
    { id: 'checkboxes', label: 'Checkboxes' },
  ];

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Forms",
    figmaLink: "https://www.figma.com/file/v6oz764LRzabocmWqiFagF/1---UI-Elements?type=design&node-id=764-2499&mode=design&t=o2oEdXTcLV2A4N6d-4",
  };

  const inputsData = {
    link: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fv6oz764LRzabocmWqiFagF%2F1---UI-Elements%3Fpage-id%3D7344%253A17386%26type%3Ddesign%26node-id%3D7344-17387%26viewport%3D666%252C386%252C0.64%26t%3DEiP2WvJme4mldHa1-1%26scaling%3Dscale-down%26starting-point-node-id%3D7344%253A17387%26mode%3Ddesign",
    title: "Inputs",
    height: "400px",
  };

  const checkboxData = {
    link: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fv6oz764LRzabocmWqiFagF%2F1---UI-Elements%3Fpage-id%3D7344%253A17386%26type%3Ddesign%26node-id%3D7344-18500%26viewport%3D773%252C217%252C0.64%26t%3DnBScvPkrftK6WXtZ-1%26scaling%3Dscale-down%26starting-point-node-id%3D7344%253A18500%26show-proto-sidebar%3D1%26mode%3Ddesign",
    title: "Checkboxes",
    height: "400px",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <div className="columns reverse">
        <main className="main">
          <h2 id="inputs" className="title-level-2 padding-level-2 anchor">Inputs</h2>
        
          <p className="large">While there are a vairety of input types (text, email, password, submit, etc.) our patterns follow consistent UI and functionality. It should be noted that when we mention fields, these include all inputs (input fields, checkboxes, radio buttons, etc...). Specific form elements will include additional style rules for each field types below.</p>

          <div className="callout">
            <h3 className="title-level-3-large padding-level-2">General Notes</h3>

            <ul className="bullets">
              <li>All inputs include, enabled, focused, error and disabled states.</li>
              <li>All inputs have conditional icons (left or right). Some include enhanced functionality (ie. password input includes a right icon for a visibility toggle).</li>
              <li>Label behaviour is consistent across all inputs - Begins inside input. Scales and repositions on focus. (Placeholder copy is optional and appears after input is focused).</li>
              <li>The width of the input field is fluid (100% width) and fills the container or columns it is placed in</li>
              <li>There is a “min-width” of 210px for all input fields</li>
              <li>Each input fields includes a bottom 24px bottom margin</li>
            </ul>
          </div>

          <div className="clear spacer level-2"></div>

          <div className="callout white">
            <h3 className="title-level-3-large padding-level-1">Restrictions</h3>
            
            <p><strong>Programatic Restrictions</strong></p>
            <ul className="bullets">
              <li>Markup should be added to fields that are “required”</li>
              <li>(App - Flutter) Support text is hidden when the error text is visible</li>
            </ul>

            <p><strong>Publishing Restrictions</strong></p>
            <ul className="bullets">
              <li>(App - Flutter) Support text is one line long</li>
            </ul>
          </div>

          <div className="clear spacer level-2"></div>

          <div className="callout white">
            <h3 className="title-level-3-large padding-level-1">Support Text Rules</h3>

            <ul className="bullets">
              <li>Use to explain desired formatting</li>
              <li>Use to provide unexpected requests (ie. Enter airmilesshops.ca for online stores)</li>
            </ul>
          </div>

          <div className="clear spacer level-2"></div>

          <div className="callout white">
            <h3 className="title-level-3-large padding-level-1">Required Label Rules</h3>

            <ul className="bullets">
              <li>Forms with 3 or fewer fields <strong>DO NOT</strong> need the required mark up (certain use cases are up to the designer's discretion)</li>
              <li>Forms with 4 or more fields <strong>DO</strong> need the required mark up</li>
              <li>Need a line of copy at the top of a page to denote “fields with asterisks are required”</li>
              <li>Required Aestrick is added beside label (ie. Email Address*)</li>
              <li>We should standardize this line of copy with the copy team</li>
            </ul>
          </div>

          <div className="clear spacer level-2"></div>

          <div className="callout white">
            <h3 className="title-level-3-large padding-level-1">Accessibility</h3>

            <ul className="bullets">
              <li>All inputs should have associated labels</li>
              <li>Support text should be used for examples</li>
              <li>Formatting expectations should be stated</li>
              <li>Required fields should be identified</li>
              <li>Colour is used to help indicate different states (i.e. error, focus, etc)</li>
              <li>Error messages should be helpful and clearly indicate the reason behind the error</li>
              <li>Focus indicators are available for buttons and inputs</li>
            </ul>
          </div>

          <div className="spacer level-2"></div>

          <div className="callout">
            <p>Coming soon: Above is work in progress. updates to prototypes + figma components will be made. Also updates to layouts to reflect required fields rules and layouts will be included in this documentation.</p>
          </div>

          <div className="spacer level-2"></div>

          <Prototype {...inputsData} />

          <div className="spacer level-4"></div>

          <h2 id="checkboxes" className="title-level-2 padding-level-2 anchor">Checkboxes</h2>
        
          <p className="large">Our checkboxes are designed to be easy to use with large click (tapable) spaces, easy to read labels and breathing room around each checkbox in a list.</p>

          <strong>Notes</strong>

          <div className="spacer level-1"></div>

          <ul>
            <li>The width of the checkbox and label is fluid (100% width) and fills the container or columns it is placed in</li>
            <li>There is a “min-width” of 210px for all checkboxes</li>
            <li>Checkbox list can have the option to include a rule between each checkbox + label</li>
              <ul>
                <li>The last checkbox + label does not have a rule below it.</li>
                <li>Bottom rules are optional and authorable</li>
              </ul>
            <li>A 24px bottom margin is included after a group of checkboxes</li>
            <li>If a group of checkboxes are “required” supporting text is included at the end of the list</li>
          </ul>

          <div className="spacer level-2"></div>

          <Prototype {...checkboxData} />
        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Forms;
