// src/pages/brand/webGlossary.js
import React from "react";

import GlobalGlossary from "../../pages/brand/Glossary";

const Glossary = () => {
  return (
    <>
      <GlobalGlossary />
    </>
  );
};

export default Glossary;
