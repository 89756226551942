// src/pages/AppAMP.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const AppFlutterComponents = () => {

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "AMP Ads",
    figmaLink: "",
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'general', label: 'General' },
    { id: 'usage', label: 'Usage' },
    { id: 'examples', label: 'Examples' },
  ];

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <p className="large">AMP Ads are a crucial part of AIR MILES. In App we uses three different ad sizes and have strict rules on placement. Here you can discover best practices for adding AMP ads to your layouts.</p>
    
      <div className="columns reverse">
        <main className="main">

          <h2 id="general" className="title-level-2 padding-level-3 anchor">General</h2>
          <ul>
            <li>Avoid placing AMP too close to main CTA.</li>
            <li>Always provide a clear option to dismiss, skip or close the AMP.</li>
            <li>Limit the amount of AMPs per page.</li>
            <li>Ensure AMPs don't interrupt collectors actions especially when they are performing critical tasks.</li>
            <li>There must be a visual separation from the content.</li> 
            <li>Avoid ads on focus flows, ie. experiences or moments in a collector journey where a key action is required - i.e Missing Miles</li>
            <li>Avoid ads from sensitive or restricted content categories (alcohol, cannabis, dating sites, etc.)</li>
            <li>Avoid ads from competing loyalty programs or financial products</li>
            <li>Avoid disruptive ad formats
              <ul>
                <li>Interstitials</li>
                <li>Ad expansions</li>
                <li>Video and/or auto-play</li>
                <li>Audio</li>
              </ul>
            </li>
          </ul>

          <div className="spacer level-2"></div>

          <h2 id="usage" className="title-level-2 padding-level-3 anchor">Usage</h2>
          
          <div className="columns">
            <div className="col two_third">
              <h3 className="title-level-3 passing-level-2">Small (320x50)</h3>
              <ul>
                <li><strong>Size:</strong> 320px width x 50px height</li>
                <li><strong>Placement:</strong> Non-intrusive, position at the top of the screen, outside any main content containers.</li>
                <li><strong>Suggestion:</strong> best for lightweight promotions that capture attention without distracting or interrupting the user experience.</li> 
                <li><strong>Container padding:</strong> 8px top/bottom and 8px left/right</li>
              </ul>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/amp/app-small-amp-a.png'} alt="" />
              <div className="spacer level-1"></div>
              <img src={process.env.PUBLIC_URL + '/images/amp/app-small-amp-b.png'} alt="" />
            </div>
          </div>

          <div className="rule"></div>

          <div className="columns">
            <div className="col two_third">
              <h3 className="title-level-3 passing-level-2">Medium (320x150)</h3>
              <ul>
                <li><strong>Size:</strong> 320px width x 120px height</li>
                <li><strong>Placement:</strong> integrate within content feeds or between sections.</li>
                <li><strong>Suggestion:</strong> Provides more space for visual content while remaining subtle enough to avoid disrupting the flow during scrolling.</li>
                <li><strong>Container padding:</strong> 8px top/bottom and 8px left/right</li>
              </ul>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/amp/app-medium-amp-a.png'} alt="" />
              <div className="spacer level-1"></div>
              <img src={process.env.PUBLIC_URL + '/images/amp/app-medium-amp-b.png'} alt="" />
            </div>
          </div>

          <div className="rule"></div>

          <div className="columns">
            <div className="col two_third">
              <h3 className="title-level-3 passing-level-2">Large (300x250)</h3>
              <ul>
                <li><strong>Size:</strong> 300px width x 250px height</li>
                <li><strong>Placement:</strong> the largest option for mobile ads, place at the bottom of the screen, outside any content containers.</li>
                <li><strong>Suggestion:</strong> highly engaging ads with a mix of visuals, text, and calls-to-action, positioned for maximum impact without disrupting user experience.</li> 
                <li><strong>Container padding:</strong> 16px top/bottom and 16px left/right</li>
              </ul>
            </div>
            <div className="col one_third">
              <img src={process.env.PUBLIC_URL + '/images/amp/app-large-amp-a.png'} alt="" />
              <div className="spacer level-1"></div>
              <img src={process.env.PUBLIC_URL + '/images/amp/app-large-amp-b.png'} alt="" />
            </div>
          </div>

          <h2 id="examples" className="title-level-2 padding-level-3 anchor">Examples</h2>
          <h3 className="title-level-3 padding-level-2">Example A</h3>
          <img src={process.env.PUBLIC_URL + '/images/amp/app-amp-example-a.jpg'} alt="" />
          
          <div className="spacer level-1"></div>

          <h3 className="title-level-3 padding-level-2">Example B</h3>
          <img src={process.env.PUBLIC_URL + '/images/amp/app-amp-example-b.jpg'} alt="" />

        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default AppFlutterComponents;