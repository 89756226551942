// src/pages/WebReleases.js
import GlobalReleases from "../../pages/overview/Releases";

const Releases = () => {
  return (
    <>
      <GlobalReleases />
    </>
  );
};

export default Releases;