// src/typography/TypographyTablet.js
import React from "react";
import { tabletTypographyData } from '../../data/typographyData';

const TypographyTablet = () => {
  return (
    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Name</th>
            <th>Typeface</th>
            <th>Weight</th>
            <th>Size</th>
            <th>Usage</th>
          </tr>
        </thead>
        <tbody>
        {tabletTypographyData.map((item, index) => (
            <tr key={index}>
                <td rel="Name"><span className={item.class}>{item.name}</span></td>
                <td rel="Typeface">{item.typeface}</td>
                <td rel="Weight">{item.weight}</td>
                <td rel="Size">{item.size}</td>
                <td rel="Usage">
                  {item.usage}
                  {item.label && <div className="badge">{item.label}</div>}
                </td>
            </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default TypographyTablet;