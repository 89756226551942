// src/pages/accessibility/AppAccessibilityOverview.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const Accessibility = () => {

  const titleBarData = {
    eyebrowText: "",
    pageTitle: "Accessibility",
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  
  const [activeSection, setActiveSection] = useState(null);
  
  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'font-size-and-scaling', label: 'Font Size & Scaling' },
    { id: 'screen-readers', label: 'Screen Readers' },
    { id: 'colour-contrast', label: 'Colour Contrast' },
  ];

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <p className="large">The AIR MILES app is built in Flutter. When it comes to accessibility, we follow <a href="https://docs.flutter.dev/ui/accessibility-and-internationalization/accessibility" target="_blank" rel="noreferrer">Flutter's best practice guidelines</a>. Below is a high level overivew of some of those guidelines.</p>
      
      <div className="columns reverse">
        <main className="main">
        
          <div className="spacer level-1"></div>

          <h2 class="title-level-2 padding-level-2">Primary areas of focus</h2>

          <div className="columns">
            <div className="col one_third">
                <span className="number">1</span>
                <div className="spacer level-1"></div>
                <h3 class="title-level-3 padding-level-1">Font size and scaling</h3>
                <p>Utilize different OS font-sizing settings and optimize components to be fluid and adaptive to accommodate different user behaviours.</p>
            </div>
            <div className="col one_third">
              <span className="number">2</span>
              <div className="spacer level-1"></div>
                <h3 class="title-level-3 padding-level-1">Screen Readers</h3>
                <p>Optimize layouts to utilize semantics for users who require assistive technologies.</p>
            </div>
            <div className="col one_third">
                <span className="number">3</span>
                <div className="spacer level-1"></div>
                <h3 class="title-level-3 padding-level-1">Colour Contrast</h3>
                <p>Ensuring all components and layouts provide a high enough colour contrast for users that are visually impared.</p>
            </div>
          </div>

          <div className="rule"></div>

          <div className="spacer level-4"></div>

          <h2 id="font-size-and-scaling" className="title-level-2 padding-level-2 anchor">Font size and scaling</h2>

          <p>Both Android and iOS contain system settings to configure the desired font sizes used by apps. Flutter text widgets respect this OS setting when determining font sizes.</p>
          <p>Font sizes are calculated automatically by Flutter based on the OS setting. However, as a developer you should make sure your layout has enough room to render all its contents when the font sizes are increased. For example, you can test all parts of your app on a small-screen device configured to use the largest font setting.</p>
          <p>We design our layouts with a width of 375px. This is to accomdate the most common screen resolutions. In layouts that have areas with desnse content, we will stress-test with layouts with 320px widths. All components should be responsive, and content fluid - to wrap and stack for graceful degredation.</p>
          <h3 className="title-level-3 padding-level-1">Common Screen Resolutions for Mobile 2024*</h3>
          <div className="columns">
            <div className="col one_sixth padding-level-1">
              <div className="callout light center">
                <span className="small">360x800</span><br />
                <span className="large"><strong>11.21%</strong></span>
              </div>
            </div>
            <div className="col one_sixth padding-level-1">
              <div className="callout light center">
                <span className="small">390x844</span><br />
                <span className="large"><strong>6.44%</strong></span>
              </div>
            </div>
            <div className="col one_sixth padding-level-1">
              <div className="callout light center">
                <span className="small">393x873</span><br />
                <span className="large"><strong>5.33%</strong></span>
              </div>
            </div>
            <div className="col one_sixth padding-level-1">
              <div className="callout light center">
                <span className="small">412x915</span><br />
                <span className="large"><strong>4.46%</strong></span>
              </div>
            </div>
            <div className="col one_sixth padding-level-1">
              <div className="callout light center">
                <span className="small">414x896</span><br />
                <span className="large"><strong>3.98%</strong></span>
              </div>
            </div>
            <div className="col one_sixth padding-level-1">
              <div className="callout light center">
                <span className="small">360x780</span><br />
                <span className="large"><strong>3.91%</strong></span>
              </div>
            </div>
          </div>

          <p className="small">*StatCounter Mobile Screen Resolution Stats Worldwide - May 2024</p>

          <div className="spacer level-2"></div>

          <h3 className="title-level-3-large padding-level-1">Font scaling Example</h3>

          <p>The following two screenshots show the AIR MILES home screen rendered with the default OS font setting, and with the largest font setting selected in OS accessibility settings.</p>
          
          <div className="spacer level-1"></div>

          <div className="columns">
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/accessibility/app-default-type.jpg'} className="border" alt="" />
              
              <div className="spacer level-1"></div>
              
              <div className="center">
                <p className="small"><em>Default font setting</em></p>
              </div>
            </div>
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/accessibility/app-large-type.jpg'} className="border" alt="" />

              <div className="spacer level-1"></div>
              
              <div className="center">
                <p className="small"><em>Largest accessibility font setting</em></p>
              </div>
            
            </div>
          </div>

          <div className="spacer level-4"></div>

          <h2 id="screen-readers" className="title-level-2 padding-level-2 anchor">Screen Readers</h2>

          <p>For visually impared mobile app users - screen readers are used to help navigate the app's UI and provide context. Flutter included a widget called <strong>Semantics</strong> which is used with assistive technologies.</p>
          <h3 className="title-level-3 padding-level-1">Semantics widget</h3>
          <p>The semantic widget is a widget that describes what a piece of the UI means. Wrapping each widget with semantics provides context to assitive technologies. Semantics includes a bunch of properties for things like "text descriptions", whether a widget should be enabled or disabled, or if it represents an input or read-only field. In fact there are over 50 properties available</p>

          <p>It is important to use the Semanitcs Widget wherever possible. <a href="https://api.flutter.dev/flutter/widgets/Semantics-class.html" target="_blank" rel="noreferrer">Visit Flutter's website</a> for more information.</p>

          <h4 className="title-level-4 padding-level-1">Semantics widget markup example</h4>
          <code>
            <ol>
              <li>{`Semantics(`}</li>
              <li><span className="indent level-1">{`child: FaceImage(`}</span></li>
              <li><span className="indent level-2">{`file: '$name.png',`}</span></li>
              <li><span className="indent level-1">{`),`}</span></li>
              <li><span className="indent level-1">{`label: 'An image of $name.',`}</span></li>
              <li><span className="indent level-1">{`enabled: true,`}</span></li>
              <li><span className="indent level-1">{`readOnly: true,`}</span></li>
              <li>{`)`}</li>
            </ol>
          </code>

          <div className="spacer level-4"></div>

          <h2 id="colour-contrast" className="title-level-2 padding-level-2 anchor">Colour Contrast</h2>
          <p className="large">We follow WCAG 2.0 level AA requirements for colour contrast. This criteria requires a contrast ratio of at least 4.5:1 for normal text and 3:1 for large text.</p>
          <p>Our designs take this requirement into consideration and is reflected in our components and layouts. Below are examples of common colour combinations utilizing our colour base tokens.</p>
          
          <h3 className="title-level-3 padding-level-2">Dark background options</h3>

          <div className="brandColours third">
            <div className="tile">
              <div className="colour sapphire900">
                Contrast Ratio<br />
                <span className="title">5.18:1</span>

                <div className="spacer level-2"></div>
                <small>Sapphire-900 Background with White Text</small>
              </div>
              
            </div>
            <div className="tile">
              <div className="colour midnight">
                Contrast Ratio<br />
                <span className="title">14.49:1</span>

                <div className="spacer level-2"></div>

                <small>Midnight-700 Background with White Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour midnight electricLimeText">
                Contrast Ratio<br />
                <span className="title">8.81:1</span>

                <div className="spacer level-2"></div>

                <small>Midnight-700 Background with Electric-lime-600 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour midnight hotPinkText">
                Contrast Ratio<br />
                <span className="title">4.62:1</span>

                <div className="spacer level-2"></div>

                <small>Midnight-700 Background with Hot-Pink-500 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour midnight lavenderText">
                Contrast Ratio<br />
                <span className="title">7.67:1</span>

                <div className="spacer level-2"></div>

                <small>Midnight-700 Background with Lavender-400 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour hotPink900">
                Contrast Ratio<br />
                <span className="title">4.89:1</span>

                <div className="spacer level-2"></div>

                <small>Hot-Pink-900 Background with White Text</small>
              </div>
            </div>
          </div>
          
          <div className="spacer level-4"></div>

          <h3 className="title-level-3 padding-level-2">Light background options</h3>

          <div className="brandColours third">
            <div className="tile">
              <div className="colour oatmylk">
                Contrast Ratio<br />
                <span className="title">14.13:1</span>

                <div className="spacer level-1"></div>

                <small>Oatmylk-100 Background with Midnight-700 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour oatmylk sapphireText">
                Contrast Ratio<br />
                <span className="title">5.05:1</span>

                <div className="spacer level-1"></div>

                <small>Oatmylk-100 Background with Sapphire-900 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour white">
                Contrast Ratio<br />
                <span className="title">14.49:1</span>

                <div className="spacer level-1"></div>
                
                <small>White Background with Midnight-700 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour white sapphireText">
                Contrast Ratio<br />
                <span className="title">5.18:1</span>

                <div className="spacer level-1"></div>
                
                <small>White Background with Sapphire-900 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour sapphire100">
                Contrast Ratio<br />
                <span className="title">12.66:1</span>

                <div className="spacer level-1"></div>
                
                <small>Sapphire-100 Background with Midnight-700 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour sapphire100 sapphireText">
                Contrast Ratio<br />
                <span className="title">4.53:1</span>

                <div className="spacer level-1"></div>
                
                <small>Sapphire-100 Background with Sapphire-900 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour sapphire200">
                Contrast Ratio<br />
                <span className="title">11.86:1</span>

                <div className="spacer level-1"></div>
                
                <small>Sapphire-200 Background with Midnight-700 Text</small>
              </div>
            </div>
            <div className="tile">
              <div className="colour lavender">
                Contrast Ratio<br />
                <span className="title">7.67:1</span>

                <div className="spacer level-1"></div>
                
                <small>SLavender-400 Background with Midnight-700 Text</small>
              </div>
            </div>
          </div>

        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Accessibility;