// src/pages/WebIcons.js
import React from "react";
import GlobalIcons from "../../pages/patterns/Icons";

const Icons = () => {

  return (
    <>
      <GlobalIcons />
    </>
  );
};

export default Icons;
