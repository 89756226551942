// src/pages/WebMarketingLandingPages.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const MarketingLandingPages = () => {

  const titleBarData = {
    eyebrowText: "Resources",
    pageTitle: "Marketing landing pages",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large"><strong>Landing Page</strong> - Best Practices for <span className="highlight alt">High Conversion</span> Rates. A landing page should serve a <span className="highlight">single purpose</span> — the defined purpose of the campaign</p>
      <p>It's all about persuasion. And the rules of design change quite a bit when it comes to designing <span className="highlight alt">conversion-centered</span> landing pages.</p>

      <div className="spacer level-4"></div>

      <h2 className="title-level-2 padding-level-2">3 Principles of Conversion Centered Design</h2>

      <div className="columns">
        <div className="col one_third">
          <h3 className="title-level-3 padding-level-1">Hold their attention</h3>
          <p>Have a 1:1 attention ratio.</p>
          <p>What does this mean? Attention ratio is the ratio between what the user <span className="highlight">can do</span> and what the user <span className="highlight">should do</span>.</p>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3 padding-level-1">Put things in context</h3>
          <p>Think about how much the user already knows and what they need to know in order for you to convince the person.</p>
          <p>Your work is to keep the main message same as that of the advertisement that brought the user to the page and also provide more information that continues to persuade.</p>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3 padding-level-1">Achieve clarity</h3>
          <p><span className="highlight">79% of web users scan the page rather than read it.</span> If we make the visitors struggle to find out about our offer, the conversion rates will go down. Make good use of headlines that prioritize being clear over being clever.</p>
        </div>
      </div>

      <div className="spacer level-4"></div>

      <div className="columns">
        <div className="col one_half">
          <h2 className="title-level-2 padding-level-2">Copy</h2>
          <p className="large">When building your landing page, check the following:</p>

          <ol>
            <li><strong>Headline</strong> (focus, relevance, benefits)</li>
            <li><strong>Sub-headline</strong> - support your value proposition and provide context to your offer</li>
            <li><strong>Message match</strong></li>
            <li><strong>Readability</strong></li>
            <li><strong>Urgency</strong> - bonus incentives, exclusivity, social proof etc.</li>
            <li><strong>Conversational tone</strong></li>
            <li><strong>Formatting and legibility</strong> - visual hierarchy, white space, no blocks of text</li>
            <li><strong>No negative speak</strong> - say “your information is safe with us” and not “we won't sell your email”</li>
          </ol>
        </div>
        <div className="col one_half">
          <img src={process.env.PUBLIC_URL + '/images/marketing/marketing-example-1.jpg'} alt="" />
        </div>
      </div>

      <div className="spacer level-4"></div>
      
      <div className="columns">
        <div className="col one_half">
          <h2 className="title-level-2 padding-level-2">Design</h2>
          <p className="large">When building your landing page, check the following:</p>

          <ol>
            <li><strong>Information hierarchy</strong> - f-shaped or z-shaped</li>
            <li><strong>Hero shot</strong> - simple, product in action, directional cues, text based clues, emotional images</li>
            <li><strong>Images</strong> - relevant, distinct, real, emotional, optimized</li>
            <li><strong>Branding</strong></li>
            <li><strong>1:1 attention ratio</strong></li>
            <li><strong>Mobile responsive</strong></li>
            <li><strong>Strategic white space</strong></li>
          </ol>
        </div>
        <div className="col one_half">
          <img src={process.env.PUBLIC_URL + '/images/marketing/marketing-example-2.jpg'} alt="" />
        </div>
      </div>

      <div className="spacer level-4"></div>

      <h2 className="title-level-2 padding-level-2">References</h2>

      <ul className="bullets">
        <li><a href="https://uxdesign.cc/how-to-create-successful-high-conversion-landing-pages-658d2984924e" target="_blank" rel="noreferrer">How to create a successful, high-conversion landing page</a></li>
        <li><a href="https://www.nngroup.com/articles/how-users-read-on-the-web/" target="_blank" rel="noreferrer">How Users Read on the Web</a></li>
        <li><a href="https://www.uxpin.com/studio/blog/landing-pages-guide/" target="_blank" rel="noreferrer">Landing Pages: The Complete Guide to Effective UX Design</a></li>
        <li><a href="https://www.klientboost.com/landing-pages/landing-page-checklist/" target="_blank" rel="noreferrer">Complete 39-Point Landing Page Checklist</a></li>
        <li><a href="https://www.klientboost.com/landing-pages/hero-shot/" target="_blank" rel="noreferrer">41 Hero Shot Secrets From High Converting Landing Pages</a></li>
      </ul>
    </>
  );
};

export default MarketingLandingPages;