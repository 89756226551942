// src/pages/WebRadius.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Radius = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Border radius",
    figmaLink: "https://www.figma.com/file/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?type=design&node-id=2235-1901&mode=design"
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Our design system includes 4 border radii.</p>

      <div className="columns noWrap">
        <div className="col one_third">
          <h3 className="title-level-3 padding-level-2">Radius</h3>

          <p>Components at different sizes require the following radius:</p>

          <ol>
            <li><strong>Small:</strong> Input fields, search bars, text areas, checkboxes, select fields, tags and accordions (mobile).</li>
            <li><strong>Medium:</strong> Cards and tiles, accordions, and “mobile” sized components (excluding accordions).</li>
            <li><strong>Large:</strong> Large containers / components. Ie. Transaction tables, carousels, modals, image containers in callouts.</li>
            <li><strong>XL:</strong> Used on buttons, number indicators, pagination, tabs, pills and radio buttons</li>
          </ol>
        </div>
        <div className="col two_third">
          <img src={process.env.PUBLIC_URL + '/images/radius/radius.png'} alt="" />
        </div>
      </div>
    </>
  );
};

export default Radius;
