// src/data/ChangeLogData.js
const ChangeLogData = [
  /*
  {
    date: "December 10, 2024",
    version: "1.3.4",
    notes: `<ul>
      <li>Added App layout details
        <ul>
          <li>Layout types</li>
          <li>IOs vs. Android</li>
          <li>Transitions</li>
        </ul>
      </li>
      <li>Added notes to Icon library - how to add icons to library</li>
      <li>Updated Icon package - with latest icons</li>
      <li>Added Grid documentation to App</li>
      <li>Updated Handoff Components - Added to App</li>
    </ul>`,
  },
  */
  {
    date: "December 6, 2024",
    version: "1.3.3",
    notes: `<ul>
      <li>Updated App Accessibility documentation (and landing) - Flutter Documentation</li>
      <li>Added Handoff component link to Resources (Web and App)</li>
      <li>Fixed inline link documentation in typography</li>
    </ul>`,
  },
  {
    date: "November 4, 2024",
    version: "1.3.2",
    notes: `<ul>
      <li>Updated rules for input fileds (some updates pending)</li>
      <li>Corrected Mobile grid screen</li>
      <li>Removed "Label" type style from typography special styles</li>
      <li>Removed Gradients from Colour library (Depricated)</li>
      <li>Removed Patterns from Colour library (Depricated)</li>
      <li>Added Flutter Component Directory page to App</li>
      <li>Added List of Flutter Components and their status</li>
      <li>Added App AMP ad usages guidelines</li>
      <li>Added section for App "Native Components" - to be completed</li>
    </ul>`,
  },
  {
    date: "September 24, 2024",
    version: "1.3.1",
    notes: `<ul>
      <li>Added new image size - 3:1</li>
      <li>Updated Icons library</li>
    </ul>`,
  },
  {
    date: "September 18, 2024",
    version: "1.3",
    notes: `<ul>
      <li>Landing page created
        <ul>
          <li>Creation of new App pages</li>
          <li>Update to navigation</li>
          <li>Indicator in header added for app vs. web</li>
          <li>Migration of App content to correct locations</li>
        </ul>
      </li>
      <li>AEM page setup tutorial added</li>
      <li>Update to icon library</li>
      <li>Removed legacy "Air Everything" icons</li>
      <li>Updated landing pages</li>
      <li>Updated "New" labels</li>
      <li>Updated role token values</li>
      <li>House keeping - update DS internal colour tokens</li>
      <li>Temporarily suppressed brand pages - to be updated with rebrand definitions</li>
      <li>Updated available AEM component list</li>
      <li>Linked to creative asset library from landing screen</li>
    </ul>`,
  },
  {
    date: "August 15, 2024",
    version: "1.2.2",
    notes: `<ul>
      <li>Addition of section padding xsmall</li>
      <li>Update to icons (more and 3 colour options)</li>
      <li>Removal of layout configuration under "resources"</li>
      <li>Update to Tutorial</li>
      <li>Update to components inventory</li>
    </ul>`,
  },
  {
    date: "July 25, 2024",
    version: "1.2.1",
    notes: `<ul>
      <li>Updated icon library</li>
      <li>Added AEM component index page to Patterns</li>
    </ul>`,
  },
  {
    date: "July 18, 2024",
    version: "1.2",
    notes: `<ul>
      <li>Tutorials section added</li>
    </ul>`,
  },
  {
    date: "July 17, 2024",
    version: "1.1.5",
    notes: `<ul>
      <li>Update "Section" defintions</li>
      <li>Update "Container" defintions</li>
      <li>Update "Composition" defintions</li>
      <li>Removed some "new" labels</li>
      <li>Added heading "size" definitions to Typography page</li>
      <li>Updated details around image component</li>
      <li>Layout Configuration section created under Resources - details to be added</li>
      <li>Added Accordions Documentation</li>
    </ul>`,
  },
  {
    date: "July 9, 2024",
    version: "1.1.4",
    notes: `<ul>
      <li>Updated type - Grouped and re-arranged definitions</li>
      <li>Deprecated styles note added for gradients and patterns</li>
      <li>Updated Blue Tier colour definitions</li>
    </ul>`,
  },
  {
    date: "April 30, 2024",
    version: "1.1.3",
    notes: `<ul>
      <li>Updated Rebrand colour names</li>
      <ul>
        <li>Midnight</li>
        <li>Electric Lime</li>
        <li>Hot Pink</li>
        <li>Oatmylk</li>
        <li>Lavender</li>
      </ul>
      <li>Update Brand Colours tab</li>
      <li>Updated layout section with new colour options for container and section</li>
      <li>Updated value of Sapphire 100</li>
      <li>Updated font rebrand details</li>
    </ul>`,
  },
  {
    date: "April 29, 2024",
    version: "1.1.2",
    notes: `<ul>
      <li>Updated Container / Section options in Layout</li>
      <ul>
        <li>Added XS padding to container</li>
        <li>Added Laven-duh-400 to Section and Container</li>
        <li>Added margin bottom options to Container</li>
      </ul>
      <li>Updating Spacing rule to H1 headings</li>
      <li>Updated Font-family to "Work Sans"</li>
      <li>Updated Font weight to H1, H2</li>
      <li>Updated logo / colours in Design System build</li>
    </ul>`,
  },
  {
    date: "April 19, 2024",
    version: "1.1.1",
    notes: `<ul>
      <li>Rebranded Colours Added</li>
      <ul>
        <li>Functional and Brand</li>
      </ul>
      <li>Form elements added to patterns</li>
      <ul>
        <li>Input fields</li>
        <li>Checkboxes</li>
      </ul>
    </ul>`,
  },
  {
    date: "March 20, 2024",
    version: "1.1.0",
    notes: `<ul>
      <li>Published Accessibility Overview</li>
      <li>Design system offically named "Atmosphere"</li>
    </ul>`,
  },
  {
    date: "March 14, 2024",
    version: "1.0.6",
    notes: `<ul>
      <li>Improved Wayfinding in build</li>
        <ul>
          <li>Landing page for foundation</li>
          <li>links from welcome page</li>
          <li>Expand landing page when accordion expands in nav</li>
          <li>Added spacer documentation under Foundation</li>
        </ul>
    </ul>`,
  },
  {
    date: "March 7, 2024",
    version: "1.0.5",
    notes: `<ul>
      <li>Added buttons to Patterns with embeded protoypes</li>
      <li>Added Publishing checklist to Resources</li>
      <li>Added new icons to library</li>
    </ul>`,
  },
  {
    date: "February 29, 2024",
    version: "1.0.4",
    notes: `<ul>
      <li>Added Marketing Landing Page Best Practices</li>
      <li>Created "Resources" section - moved pages out of "Overview"</li>
      <li>Updated Layout documentation to match AEM</li>
      <li>Minor layout updates</li>
    </ul>`,
  },
  {
    date: "February 14, 2024",
    version: "1.0.3",
    notes: `<ul>
      <li>Fixed anchor bug on page change</li>
      <li>Addition of "Brand Guide" section and sub sections with populated sections:
        <ul>
          <li>Overview</li>
          <li>Logo</li>
          <li>Colour Palette</li>
          <li>Glossary</li>  
        </ul>
        <li>Addition of App resources on welcome page</li>
        <li>Update logo in header</li>
        <li>Fixed sidebar scrolling</li>
    </ul>`,
  },
  {
    date: "February 9, 2024",
    version: "1.0.2",
    notes: `<ul>
      <li>Bug identified - no vertical scroll on sidebar for short viewports</li>
      <li>Updated tables for mobile</li>
      <li>Technical: grouped pages in build</li>
      <li>Added border radius to foundation</li>
      <li>Added image component to patterns</li>
      <li>Updated Icon list to include missing icons from App</li>
    </ul>`,
  },
  {
    date: "January 5, 2024",
    version: "1.0.1",
    notes: `<ul>
      <li>Back to top function added</li>
      <li>Jump to section sidebar added to some pages</li>
      <li>Additional documentation added to Typography</li>
      <li>Elevation added to foundations</li>
      <li>New page checklist added to Overview</li>
      <li>New design checklist added to Overview</li>
      <li>Placeholder Accessibility tab added</li>
      <li>Handoff tools added to Overview</li>
      <li>Exploration of AEM experiences embed (buttons page - not accurate content)</li>
    </ul>`,
  },
  {
    date: "December 21, 2023",
    version: "1.0.0",
    notes: `<ul>
      <li>Established design system environment</li>
      <li>Setup Foundations
        <ul>
          <li>Colour system</li>
          <li>Typography</li>
          <li>Grid system</li>
          <li>Layout</li>
        </ul>
      </li>
      <li>Setup Patterns
        <ul>
          <li>Buttons</li>
        </ul>
      </li>
      <li>Setup Tokens</li>
    </ul>`,
  },
];

export default ChangeLogData;