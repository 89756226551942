// src/pages/AppFlutterComponents.js
import React from "react";

import TitleBar from "../../components/TitleBar";
import flutterComponentsData from "../../data/flutterComponentsData";

const AppFlutterComponents = () => {

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Flutter Components",
    figmaLink: "",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">We use <span className="highlight">Flutter</span> for Air Miles <span className="highlight alt">app</span>, we utilize Flutter's core components as well as develop custom components. Discover what components are available in Flutter.</p>
    
      <h2 className="title-level-2 padding-level-2">Available Components</h2>

      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th>Component</th>
              <th>Details</th>
              <th>Status</th>
              <th>Design</th>
            </tr>
          </thead>
          <tbody>
          {flutterComponentsData.map((item, index) => (
            <tr key={index}>
                <td rel="Component">{item.Component}</td>
                <td rel="Details">{item.Details}</td>
                <td rel="Status"><span className="status">{item.Status}</span></td>
                <td rel="Design">
                {/* Conditionally render Figma link */}
                {item.figmaLink && (
                  <a
                    href={item.figmaLink}
                    rel="noreferrer"
                    target="_blank"
                    className="figma-btn"
                  >
                    View in Figma
                  </a>
                )}  
                </td>     
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AppFlutterComponents;