// src/typography/TypographyBody.js
import React from "react";
import { bodyTypographyData } from '../../data/typographyData';
import { specialTypographyData } from '../../data/typographyData';

const TypographyBody = () => {
  return (
    <>
    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Name</th>
            <th>Typeface</th>
            <th>Weight</th>
            <th>Size</th>
            <th>Usage</th>
          </tr>
        </thead>
        <tbody>
        {bodyTypographyData.map((item, index) => (
            <tr key={index}>
                <td rel="Name"><span className={item.class}>{item.name}</span></td>
                <td rel="Typeface">{item.typeface}</td>
                <td rel="Weight">{item.weight}</td>
                <td rel="Size">{item.size}</td>
                <td rel="Usage">
                  {item.usage}
                  {item.label && <div className="badge">{item.label}</div>}
                </td>
            </tr>
        ))}
        </tbody>
      </table>
    </div>

    <div className="spacer level-2"></div>

    <h3 className="title-level-3 padding-level-1">Special Styles</h3>

    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Name</th>
            <th>Typeface</th>
            <th>Weight</th>
            <th>Size</th>
            <th>Usage</th>
          </tr>
        </thead>
        <tbody>
        {specialTypographyData.map((item, index) => (
            <tr key={index}>
                <td rel="Name"><span className={item.class}>{item.name}</span></td>
                <td rel="Typeface">{item.typeface}</td>
                <td rel="Weight">{item.weight}</td>
                <td rel="Size">{item.size}</td>
                <td rel="Usage">
                  {item.usage}
                  {item.label && <div className="badge">{item.label}</div>}
                </td>
            </tr>
        ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default TypographyBody;