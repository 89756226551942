// src/pages/brand/Glossary.js
import React from "react";

import TitleBar from "../../components/TitleBar";

import { glossaryData } from '../../data/glossaryData';

const Brand = () => {

  const titleBarData = {
    eyebrowText: "Brand",
    pageTitle: "Glossary",
  };

  return (
    <>
      <TitleBar {...titleBarData} />

      <div className="table">
        <table cellPadding="0" cellSpacing="0" border="none">
          <thead className="sticky">
            <tr>
              <th>Term</th>
              <th>Definition</th>
            </tr>
          </thead>
          <tbody>
            {glossaryData.map((item, index) => (
              <tr key={index}>
                <td rel="Term"><strong>{item.term}</strong></td>
                <td rel="Definition">{item.definition}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Brand;
