// src/pages/AppNativeComponents.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const AppFlutterComponents = () => {

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Native Components",
    figmaLink: "",
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'ios-keyboard', label: 'iOS Keyboard' },
  ];

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <p className="large">While we use <span className="highlight">Flutter</span> for the majority of our components, there are still scenarios where we need to utilize <span className="highlight alt">Native iOS and Android</span> components. Here you can find what those components are and how we use them.</p>
    
      <div className="columns reverse">
        <main className="main">

          <h2 id="ios-keyboard" className="title-level-2 padding-level-3 anchor">IOS Keyboard</h2>
          <p>Details coming soon.</p>
        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default AppFlutterComponents;