// src/pages/WebGrids.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const Grids = () => {

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'sizes', label: 'Sizes' },
    { id: 'gutters', label: 'Gutters' },
    { id: 'responsive', label: 'Responsive' },
    { id: 'usage', label: 'Usage' },
  ];

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Grids",
    figmaLink: "https://www.figma.com/file/c8ptjKSfQBqSFoUi1uCSJC/1---Grids?type=design&node-id=30%3A28&mode=design&t=tMfycg63qDugvqGP-1",
  };

  return (
    <>
      <div id="overview"></div>
      <TitleBar {...titleBarData} />
      <p className="large">We use a 12 column grid with percent based column and gutters. The grid is used for layout of components. Component spacing, and component groupings can have unique spacing.</p>
    
      <div className="columns reverse">
        <main className="main">

          <h2 id="sizes" className="title-level-2 padding-level-2 anchor">Grid sizes &amp; combinations</h2>

          <p>The grid layout is easily customizable by mixing different column sizes and combinations.</p>

          <span className="label">6/6</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-6.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">4/4/4</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-4.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">4/8</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-4-8.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">8/4</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-8-4.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">3/3/3</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-3.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">3/3/6</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-3-3-6.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">6/3/3</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-6-3-3.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">3/6/3</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-3-6-3.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">3/9</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-3-9.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">9/3</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-9-3.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">3/8</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-3-8.png'} alt="" />

          <div className="spacer level-2"></div>

          <span className="label">8/3</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-8-3.png'} alt="" />
          
          <div className="spacer level-2"></div>

          <span className="label">6/6</span>
          <div className="spacer level-1"></div>
          <img src={process.env.PUBLIC_URL + '/images/grid/grid-6.png'} alt="" />
        
          <div className="spacer level-2"></div>

          <h2 id="gutters" className="title-level-2 padding-level-2 anchor">Gutters &amp; fluidity</h2>

          <p>Our grid columns use a 3.2% gutter. This ensures that our grid scales fluidly from desktop to mobile. While we don't reduce our grid from 12 columns - column configurations can vary between breakpoints.</p>

          <div className="spacer level-1"></div>

          <img src={process.env.PUBLIC_URL + '/images/grid/column-gutters.png'} alt="" />

          <div className="spacer level-2"></div>

          <h2 id="responsive" className="title-level-2 padding-level-2 anchor">Responsive behaviour</h2>

          <div className="callout white">
            <h3 className="title-level-3 padding-level-1">Desktop</h3>
            <p>Here we have a layout with a "main" content area and a "sidebar". The main area occupies 9 columns while the sidebar takes up 3 columns. Our desktop grid is in an inner container with a max-width of 1140px with "auto" left and right margins. This means that our grid is horizontally centered in our layout.</p>
            <img src={process.env.PUBLIC_URL + '/images/grid/grid-desktop.png'} alt="" className="border" />
          </div>

          <div className="spacer level-2"></div>

          <div className="callout white">
            <div className="columns">
              <div className="col one_fourth">
                <h3 className="title-level-3 padding-level-1">Tablet Landscape</h3>
                <p>In this example, there is little difference between Desktop and Tablet Landscape. The column configuration is the same - the column and gutter widths change because they are percent based. Tablet Landscape inner container has 48px left and right padding.</p>
              </div>
              <div className="col three_fourth">
                <img src={process.env.PUBLIC_URL + '/images/grid/grid-tablet-landscape.png'} alt="" className="border" style={{ maxWidth: '1024px', width: '100%' }} /> 
              </div>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <div className="callout white">
            <div className="columns">
              <div className="col one_third">
              <h3 className="title-level-3 padding-level-1">Tablet Portrait</h3>
                <p>For Tablet Portrait, we can change the columns composition of our layout. From here, we have our main container occupying 8 columns with our sidebar now occupyting 4 columns. Our tablet grid occupies 100% of the viewport width, with the inner container having 24px of left and right padding.</p>
              </div>
              <div className="col two_third">
                <img src={process.env.PUBLIC_URL + '/images/grid/grid-tablet-portrait.png'} alt="" className="border" style={{ maxWidth: '768px', width: '100%' }} /> 
              </div>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <div className="callout white">
            <div className="columns">
              <div className="col one_half">
              <h3 className="title-level-3 padding-level-1">Mobile</h3>
                <p>For Mobile, we can make the columns 100% of the view port. We also allow for the columns stacking to be reveresed. In traditional column stacking, right containers go below left containers. We don't restrict our columns in this way. Proper annotations must be provided to development / publishing team in this scenario. Our mobile grid occupies 100% of the viewport width, with the inner container having 16px of left and right padding.</p>
              </div>
              <div className="col one_half">
                <img src={process.env.PUBLIC_URL + '/images/grid/grid-mobile.png'} alt="" className="border" style={{ maxWidth: '375px', margin: '0 auto' }} />
              </div>
            </div>
          </div>

          <div className="spacer level-2"></div>

          <h2 id="usage" className="title-level-2 padding-level-2 anchor">Usage guidelines</h2>

          <div className="columns noWrap">
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/grid/grid-layout-do.png'} alt="" className="border" />

              <div className="spacer level-2"></div>
              <span className="tag-do">Do</span>
              <p>Use the grid to organize components and groupd of components on a page. Grouped components can have custom gutter size - different than what the grid defines (including different units of measurement - pixels vs. percentages).</p>
            </div>
            <div className="col one_half">
              <img src={process.env.PUBLIC_URL + '/images/grid/grid-layout-dont.png'} alt="" className="border" />

              <div className="spacer level-2"></div>
              <span className="tag-dont">Don't</span>
              <p>Rely on the grid for spacing of groupped components. The gutters are designed to provide appropriate white space around components and component groupings to improve readability. Using this spacing for grouped components can create a too much spacing.</p>
            </div>
          </div>
        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Grids;
