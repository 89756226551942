// src/tokens/AppTokensSizing.js
import React from "react";
import { sizingTokenData } from '../../data/tokenData';

const TokensSizing = () => {
  return (
    <>
    <div className="table">
      <table cellPadding="0" cellSpacing="0" border="none">
        <thead className="sticky">
          <tr>
            <th>Token</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {sizingTokenData.map((item, index) => (
            <tr key={index}>
                <td rel="Token">{item.token}</td>
                <td rel="Value">{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default TokensSizing;