// src/typography/TypographyBody.js
import React from "react";

const TypographyBody = () => {
  return (
    <>
    <h3 className="title-level-3 padding-level-1">Usage</h3>

    <p><strong>Spacing:</strong> include 24px padding below each paragraph.<br />
    <strong>Sizing:</strong> body copy is available in 4 font sizes.<br />
    <strong>Colour:</strong> All body copy is midnight-700 on light backgrounds and White on dark backgrounds.</p>

    <h3 className="title-level-3 padding-level-1">Inline Links</h3>

    <h4 className="title-level-4 padding-level-1">States (on light background):</h4>

    <div className="columns noWrap">
      <div className="col one_third">
        <p><strong>Default:</strong><br /> 
        <strong>color:</strong> Sapphire-900<br />
        <strong>text-decoration:</strong> underline</p>
      </div>
      <div className="col one_third">
        <p><strong>Hover:</strong><br /> 
        <strong>color:</strong> Sapphire-900<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>text-decoration-thickness:</strong> 2px</p>
      </div>
      <div className="col one_third">
        <p><strong>Focus:</strong><br /> 
        <strong>color:</strong> Sapphire-900<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>outline:</strong> 2px solid emerald-900</p>
      </div>
    </div>

    <div className="spacer level-2"></div>

    <img src={process.env.PUBLIC_URL + '/images/typography/body-copy-light.png'} alt="" className="border" />

    <div className="spacer level-4"></div>

    <h4 className="title-level-4 padding-level-1">States (on dark background - Midnight-700):</h4>

    <div className="columns noWrap">
      <div className="col one_third">
        <p><strong>Default:</strong><br /> 
        <strong>color:</strong> Electric-lime-600<br />
        <strong>text-decoration:</strong> underline</p>
      </div>
      <div className="col one_third">
        <p><strong>Hover:</strong><br /> 
        <strong>color:</strong> Electric-lime-600<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>text-decoration-thickness:</strong> 2px</p>
      </div>
      <div className="col one_third">
        <p><strong>Focus:</strong><br /> 
        <strong>color:</strong> Electric-lime-600<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>outline:</strong> 2px solid emerald-900</p>
      </div>
    </div>

    <div className="spacer level-2"></div>

    <img src={process.env.PUBLIC_URL + '/images/typography/body-copy-dark.png'} alt="" />

    </>
  );
};

export default TypographyBody;