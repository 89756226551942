// src/colours/coloursData.js

// FUNCTIONAL COLOURS
const coloursFunctionalData = [
    {
      name: "White",
      description: "",
      colors: [
        {
          hex: "#FFFFFF",
          colourName: "white",
          coreTokenName: "--am-base-colour-white",
          usage: "Misc.",
          border: "border",
        },
      ],
    },
    {
      name: "Steel",
      description: "Used primarly for typographic elements, rules and strokes",
      colors: [
        {
          hex: "#1C2D3F",
          colourName: "steel-900",
          coreTokenName: "--am-base-colour-steel-900",
          usage: "",
        },
        {
          hex: "#2C3E50",
          colourName: "steel-800",
          coreTokenName: "--am-base-colour-steel-800",
          usage: "-",
        },
        {
          hex: "#384A5B",
          colourName: "steel-700",
          coreTokenName: "--am-base-colour-steel-700",
          usage: "",
        },
        {
          hex: "#485B6C",
          colourName: "steel-600",
          coreTokenName: "--am-base-colour-steel-600",
          usage: "-",
        },
        {
          hex: "#5A6B7B",
          colourName: "steel-500",
          coreTokenName: "--am-base-colour-steel-500",
          usage: "Placeholder text (input fields), Inactive copy, Container borders, Input strokes",
        },
        {
          hex: "#8A99A7",
          colourName: "steel-400",
          coreTokenName: "--am-base-colour-steel-400",
          usage: "Active scrub / scroll bars",
        },
        {
          hex: "#A6B1BB",
          colourName: "steel-300",
          coreTokenName: "--am-base-colour-steel-300",
          usage: "Table border",
        },
        {
          hex: "#C7D2DC",
          colourName: "steel-200",
          coreTokenName: "--am-base-colour-steel-200",
          usage: "Dividing rules, tile borders",
        },
        {
          hex: "#EDF1F4",
          colourName: "steel-100",
          coreTokenName: "--am-base-colour-steel-100",
          usage: "Some container borders, Inactive scrub / scroll bars",
        },
      ],
    },
    {
      name: "Sapphire",
      description: "Used for Buttons, links, backgrounds and icons",
      colors: [
        {
          hex: "#1F68DA",
          colourName: "sapphire-900",
          coreTokenName: "--am-base-colour-sapphire-900",
          usage: "Inline links, button backgrounds and strokes - meets accesibility requirements for interactive elements <br /><br /><strong>Add:</strong> AM logo, Background fills (with white copy), AM Brand elements, AIR Everything - Online icon",
        },
        {
          hex: "#2A75DB",
          colourName: "sapphire-800",
          coreTokenName: "--am-base-colour-sapphire-800",
          usage: "",
        },
        {
          hex: "#4185E2",
          colourName: "sapphire-700",
          coreTokenName: "--am-base-colour-sapphire-700",
          usage: "-",
        },
        {
          hex: "#5B97EA",
          colourName: "sapphire-600",
          coreTokenName: "--am-base-colour-sapphire-600",
          usage: "-",
        },
        {
          hex: "#6FA6F1",
          colourName: "sapphire-500",
          coreTokenName: "--am-base-colour-sapphire-500",
          usage: "-",
        },
        {
          hex: "#9CC5FC",
          colourName: "sapphire-400",
          coreTokenName: "--am-base-colour-sapphire-400",
          usage: "Inactive progress (see progress bars)",
        },
        {
          hex: "#C3DDFF",
          colourName: "sapphire-300",
          coreTokenName: "--am-base-colour-sapphire-300",
          usage: "-",
        },
        {
          hex: "#DAEAFF",
          colourName: "sapphire-200",
          coreTokenName: "--am-base-colour-sapphire-200",
          usage: "Used for Section & Container backgrounds",
        },
        {
          hex: "#E5F1FF",
          colourName: "sapphire-100",
          coreTokenName: "--am-base-colour-sapphire-100",
          usage: "Used for Section & Container backgrounds. (Updated from #F0F5F8)",
        }
      ],
    },
    {
      name: "Emerald",
      description: "Used for success messages, focus states and highlights",
      colors: [
        {
          hex: "#16A78A",
          colourName: "emerald-900",
          coreTokenName: "--am-base-colour-emerald-900",
          usage: "Focus outline stroke, success icons & stroke",
        },
        {
          hex: "#24C9A9",
          colourName: "emerald-800",
          coreTokenName: "--am-base-colour-emerald-800",
          usage: "-",
        },
        {
          hex: "#3BD6B8",
          colourName: "emerald-700",
          coreTokenName: "--am-base-colour-emerald-700",
          usage: "-",
        },
        {
          hex: "#4FE1C5",
          colourName: "emerald-600",
          coreTokenName: "--am-base-colour-emerald-600",
          usage: "-",
        },
        {
          hex: "#68E9D0",
          colourName: "emerald-500",
          coreTokenName: "--am-base-colour-emerald-500",
          usage: "-",
        },
        {
          hex: "#98F1E0",
          colourName: "emerald-400",
          coreTokenName: "--am-base-colour-emerald-400",
          usage: "-",
        },
        {
          hex: "#B8FAED",
          colourName: "emerald-300",
          coreTokenName: "--am-base-colour-emerald-300",
          usage: "-",
        },
        {
          hex: "#D9FFF7",
          colourName: "emerald-200",
          coreTokenName: "--am-base-colour-emerald-200",
          usage: "-",
        },
        {
          hex: "#EAFEFA",
          colourName: "emerald-100",
          coreTokenName: "--am-base-colour-emerald-100",
          usage: "Success background fill",
        },
      ],
    },
    {
      name: "Ruby",
      description: "Used for error messages",
      colors: [
        {
          hex: "#E71823",
          colourName: "ruby-900",
          coreTokenName: "--am-base-colour-ruby-900",
          usage: "Error icon & stroke",
        },
        {
          hex: "#FD4D55",
          colourName: "ruby-800",
          coreTokenName: "--am-base-colour-ruby-800",
          usage: "-",
        },
        {
          hex: "#FE6067",
          colourName: "ruby-700",
          coreTokenName: "--am-base-colour-ruby-700",
          usage: "-",
        },
        {
          hex: "#FC767C",
          colourName: "ruby-600",
          coreTokenName: "--am-base-colour-ruby-600",
          usage: "-",
        },
        {
          hex: "#FF8388",
          colourName: "ruby-500",
          coreTokenName: "--am-base-colour-ruby-500",
          usage: "-",
        },
        {
          hex: "#FF9498",
          colourName: "ruby-400",
          coreTokenName: "--am-base-colour-ruby-400",
          usage: "-",
        },
        {
          hex: "#FFA6A9",
          colourName: "ruby-300",
          coreTokenName: "--am-base-colour-ruby-300",
          usage: "-",
        },
        {
          hex: "#FFC5C7",
          colourName: "ruby-200",
          coreTokenName: "--am-base-colour-ruby-200",
          usage: "-",
        },
        {
          hex: "#FCF3F3",
          colourName: "ruby-100",
          coreTokenName: "--am-base-colour-ruby-100",
          usage: "Error background fill",
        },
      ],
    },
    {
      name: "Citrine",
      description: "Used for warning messages",
      colors: [
        {
          hex: "#E19708",
          colourName: "citrine-900",
          coreTokenName: "--am-base-colour-citrine-900",
          usage: "Warning icon & stroke",
        },
        {
          hex: "#FFBD00",
          colourName: "citrine-800",
          coreTokenName: "--am-base-colour-citrine-800",
          usage: "-",
        },
        {
          hex: "#FDC82F",
          colourName: "citrine-700",
          coreTokenName: "--am-base-colour-citrine-700",
          usage: "-",
        },
        {
          hex: "#FFD14D",
          colourName: "citrine-600",
          coreTokenName: "--am-base-colour-citrine-600",
          usage: "-",
        },
        {
          hex: "#FFD969",
          colourName: "citrine-500",
          coreTokenName: "--am-base-colour-citrine-500",
          usage: "-",
        },
        {
          hex: "#FFE395",
          colourName: "citrine-400",
          coreTokenName: "--am-base-colour-citrine-400",
          usage: "-",
        },
        {
          hex: "#FFEFC1",
          colourName: "citrine-300",
          coreTokenName: "--am-base-colour-citrine-300",
          usage: "-",
        },
        {
          hex: "#FFF5D7",
          colourName: "citrine-200",
          coreTokenName: "--am-base-colour-citrine-200",
          usage: "-",
        },
        {
          hex: "#FFFAEC",
          colourName: "citrine-100",
          coreTokenName: "--am-base-colour-citrine-100",
          usage: "Warning background fill",
        },
      ],
    },
  ];

  // TIER COLOURS
  const coloursTierData = [
    {
      name: "Blue",
      description: "Blue tier collectors",
      colors: [
        {
          hex: "#1F68DA",
          colourName: "blue-900",
          coreTokenName: "--am-base-colour-blue-900",
          usage: "Primary colour used for blue tier",
        },
        {
          hex: "#2A75DB",
          colourName: "blue-800",
          coreTokenName: "--am-base-colour-blue-800",
          usage: "-",
        },
        {
          hex: "#4185E3",
          colourName: "blue-700",
          coreTokenName: "--am-base-colour-Blue-700",
          usage: "-",
        },
      ],
    },
    {
      name: "Gold",
      description: "Gold tier collectors",
      colors: [
        {
          hex: "#785C09",
          colourName: "gold-900",
          coreTokenName: "--am-base-colour-gold-900",
          usage: "Primary colour used for Gold tier",
        },
        {
          hex: "#8A6A0A",
          colourName: "gold-800",
          coreTokenName: "--am-base-colour-gold-800",
          usage: "-",
        },
        {
          hex: "#A98310",
          colourName: "gold-700",
          coreTokenName: "--am-base-colour-gold-700",
          usage: "-",
        },
      ],
    },
    {
      name: "Onyx",
      description: "Onyx tier collectors",
      colors: [
        {
          hex: "#000000",
          colourName: "onyx-900",
          coreTokenName: "--am-base-colour-onyx-900",
          usage: "Primary colour used for Onyx tier",
        },
        {
          hex: "#111111",
          colourName: "onyx-800",
          coreTokenName: "--am-base-colour-onyx-800",
          usage: "-",
        },
        {
          hex: "#222222",
          colourName: "onyx-700",
          coreTokenName: "--am-base-colour-onyx-700",
          usage: "-",
        },
      ],
    },
  ];

  // BRAND COLOURS
  const coloursBrandData = [
    {
      name: "Midnight",
      description: "",
      colors: [
        {
          hex: "#080E1A",
          colourName: "midnight-900",
          coreTokenName: "--am-base-colour-midnight-900",
          usage: "-",
        },
        {
          hex: "#0F1D35",
          colourName: "midnight-800",
          coreTokenName: "--am-base-colour-midnight-800",
          usage: "-",
        },
        {
          hex: "#19284C",
          colourName: "midnight-700",
          coreTokenName: "--am-base-colour-midnight-700",
          usage: "AM Brand colour, AIR Everything - In-store icon, Background of containers / sections, text & icon colours of buttons (hover and pressed states), Headings 1-6, Labels, Body copy - improves readability and provides hierarchy with headings",
        },
        {
          hex: "#162C4F",
          colourName: "midnight-600",
          coreTokenName: "--am-base-colour-midnight-600",
          usage: "-",
        },
        {
          hex: "#1D3B6A",
          colourName: "midnight-500",
          coreTokenName: "--am-base-colour-midnight-500",
          usage: "-",
        },
        {
          hex: "#304E96",
          colourName: "midnight-400",
          coreTokenName: "--am-base-colour-midnight-400",
          usage: "-",
        },
        {
          hex: "#5B82E0",
          colourName: "midnight-300",
          coreTokenName: "--am-base-colour-midnight-300",
          usage: "-",
          
        },
        {
          hex: "#94AFF0",
          colourName: "midnight-200",
          coreTokenName: "--am-base-colour-midnight-200",
          usage: "-",
          
        },
        {
          hex: "#D8E4FF",
          colourName: "midnight-100",
          coreTokenName: "--am-base-colour-midnight-100",
          usage: "-",
          
        },
      ],
    },
    {
      name: "Lavender",
      description: "Updated for Rebrand",
      colors: [
        {
          hex: "#940FBD",
          colourName: "lavender-900",
          coreTokenName: "--am-base-colour-lavender-900",
          usage: "-",
          
        },
        {
          hex: "#A53AC9",
          colourName: "lavender-800",
          coreTokenName: "--am-base-colour-lavender-800",
          usage: "-",
          
        },
        {
          hex: "#B657D5",
          colourName: "lavender-700",
          coreTokenName: "--am-base-colour-lavender-700",
          usage: "-",
          
        },
        {
          hex: "#C672E1",
          colourName: "lavender-600",
          coreTokenName: "--am-base-colour-lavender-600",
          usage: "-",
          
        },
        {
          hex: "#D68BEC",
          colourName: "lavender-500",
          coreTokenName: "--am-base-colour-lavender-500",
          usage: "-",
          
        },
        {
          hex: "#E6A5FA",
          colourName: "lavender-400",
          coreTokenName: "--am-base-colour-lavender-400",
          usage: "Rebrand Base Colour, Background of containers / sections, Title (H1, H1 Large, H1 Small, H2, H2 Large, H2 XLarge) colour on Midnight 700",
          
        },
        {
          hex: "#ECBDFA",
          colourName: "lavender-300",
          coreTokenName: "--am-base-colour-lavender-300",
          usage: "-",
          
        },
        {
          hex: "#F3D5FC",
          colourName: "lavender-200",
          coreTokenName: "--am-base-colour-lavender-200",
          usage: "-",
          
        },
        {
          hex: "#F9ECFD",
          colourName: "lavender-100",
          coreTokenName: "--am-base-colour-lavender-100",
          usage: "-",
          
        },
      ],
    },
    {
      name: "Hot Pink",
      description: "Updated for Rebrand",
      colors: [
        {
          hex: "#DD0369",
          colourName: "hot-pink-900",
          coreTokenName: "--am-base-colour-hot-pink-900",
          usage: "-",
          
        },
        {
          hex: "#E52177",
          colourName: "hot-pink-800",
          coreTokenName: "--am-base-colour-hot-pink-800",
          usage: "-",
          
        },
        {
          hex: "#ED3384",
          colourName: "hot-pink-700",
          coreTokenName: "--am-base-colour-hot-pink-700",
          usage: "-",
          
        },
        {
          hex: "#F54292",
          colourName: "hot-pink-600",
          coreTokenName: "--am-base-colour-hot-pink-600",
          usage: "-",
          
        },
        {
          hex: "#FF499E",
          colourName: "hot-pink-500",
          coreTokenName: "--am-base-colour-hot-pink-500",
          usage: "Rebranded Base Colour, Background of containers / sections, Title(H1, H1 Large, H1 Small, H2, H2 Large, H2 XLarge) colour on Midnight 700, White, and Oatmylk 100",
          
        },
        {
          hex: "#FF7EB7",
          colourName: "hot-pink-400",
          coreTokenName: "--am-base-colour-hot-pink-400",
          usage: "-",
          
        },
        {
          hex: "#FFA4CD",
          colourName: "hot-pink-300",
          coreTokenName: "--am-base-colour-hot-pink-300",
          usage: "-",
          
        },
        {
          hex: "#FFC8E1",
          colourName: "hot-pink-200",
          coreTokenName: "--am-base-colour-hot-pink-200",
          usage: "-",
          
        },
        {
          hex: "#FFEBF4",
          colourName: "hot-pink-100",
          coreTokenName: "--am-base-colour-hot-pink-100",
          usage: "-",
          
        },
      ],
    },
    {
      name: "Electric Lime",
      description: "Updated for Rebrand",
      colors: [
        {
          hex: "#688D02",
          colourName: "electric-lime-900",
          coreTokenName: "--am-base-colour-electric-lime-900",
          usage: "-",
          
        },
        {
          hex: "#73980F",
          colourName: "electric-lime-800",
          coreTokenName: "--am-base-colour-electric-lime-800",
          usage: "-",
          
        },
        {
          hex: "#8EB81C",
          colourName: "electric-lime-700",
          coreTokenName: "--am-base-colour-electric-lime-700",
          usage: "-",
          
        },
        {
          hex: "#AADA29",
          colourName: "electric-lime-600",
          coreTokenName: "--am-base-colour-electric-lime-600",
          usage: "Button background pressed",
          
        },
        {
          hex: "#C6FE01",
          colourName: "electric-lime-500",
          coreTokenName: "--am-base-colour-electric-lime-500",
          usage: "Rebranded Base Colour, Button background hover, Background of containers / sections, Title (H1, H1 Large, H1 Small, H2, H2 Large, H2 XLarge) colour on Midnight 700",
          
        },
        {
          hex: "#D2FD5E",
          colourName: "electric-lime-400",
          coreTokenName: "--am-base-colour-electric-lime-400",
          usage: "-",
          
        },
        {
          hex: "#DEFD86",
          colourName: "electric-lime-300",
          coreTokenName: "--am-base-colour-electric-lime-300",
          usage: "-",
          
        },
        {
          hex: "#E9FEAF",
          colourName: "electric-lime-200",
          coreTokenName: "--am-base-colour-electric-lime-200",
          usage: "-",
          
        },
        {
          hex: "#F4FED7",
          colourName: "electric-lime-100",
          coreTokenName: "--am-base-colour-electric-lime-100",
          usage: "-",
          
        },
      ],
    },
    {
      name: "Oatmylk",
      description: "To be added as part of Rebrand",
      colors: [
        {
          hex: "#FFE70A",
          colourName: "oatmylk-900",
          coreTokenName: "--am-base-colour-oatmylk-900",
          usage: "-",
          
        },
        {
          hex: "#FFEA41",
          colourName: "oatmylk-800",
          coreTokenName: "--am-base-colour-oatmylk-800",
          usage: "-",
          
        },
        {
          hex: "#FFED5F",
          colourName: "oatmylk-700",
          coreTokenName: "--am-base-colour-oatmylk-700",
          usage: "-",
          
        },
        {
          hex: "#FFF079",
          colourName: "oatmylk-600",
          coreTokenName: "--am-base-colour-oatmylk-600",
          usage: "-",
          
        },
        {
          hex: "#FFF391",
          colourName: "oatmylk-500",
          coreTokenName: "--am-base-colour-oatmylk-500",
          usage: "-",
          
        },
        {
          hex: "#FFF6A8",
          colourName: "oatmylk-400",
          coreTokenName: "--am-base-colour-oatmylk-400",
          usage: "-",
          
        },
        {
          hex: "#FFF8BF",
          colourName: "oatmylk-300",
          coreTokenName: "--am-base-colour-oatmylk-300",
          usage: "-",
          
        },
        {
          hex: "#FFFBD5",
          colourName: "oatmylk-200",
          coreTokenName: "--am-base-colour-oatmylk-200",
          usage: "-",
          
        },
        {
          hex: "#FFFDE9",
          colourName: "oatmylk-100",
          coreTokenName: "--am-base-colour-oatmylk-100",
          usage: "Rebranded Base Colour, Background of containers / sections",
          
        },
      ],
    },
  ];

  // GRADIENTS
  const coloursGradientsData = [
    {
      name: "Functional Gradients",
      description: "",
      colors: [
        {
          hex1: "#1C2D3F",
          hex2: "#384A5B",
          colourName: "steel-gradient",
          coreTokenName: "--am-base-colour-steel-gradient",
          usage: "-",
          label: "Deprecated",
        },
        {
          hex1: "#1F68DA",
          hex2: "#4185E2",
          colourName: "sapphire-gradient",
          coreTokenName: "--am-base-colour-sapphire-gradient",
          usage: "Hero backgrounds, Callout containers",
          label: "Deprecated",
        },
        {
          hex1: "#16A78A",
          hex2: "#3BD6B8",
          colourName: "emerald-gradient",
          coreTokenName: "--am-base-colour-emerald-gradient",
          usage: "-",
          label: "Deprecated",
        },
        {
          hex1: "#FD4D55",
          hex2: "#FE6067",
          colourName: "ruby-gradient",
          coreTokenName: "--am-base-colour-ruby-gradient",
          usage: "-",
          label: "Deprecated",
        },
        {
          hex1: "#E19708",
          hex2: "#FDC82F",
          colourName: "citrine-gradient",
          coreTokenName: "--am-base-colour-citrine-gradient",
          usage: "-",
          label: "Deprecated",
        },
        {
          hex1: "#C7D2DC",
          hex2: "#EDF1F4",
          colourName: "skeleton-loader-gradient",
          coreTokenName: "--am-base-colour-grey-white-grey-gradient",
          usage: "Placeholder content in skeleton loader",
        },
      ],
    },
  ];
  
  export { coloursFunctionalData, coloursTierData, coloursBrandData, coloursGradientsData };

  